import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.css']
})
export class PaymentViewComponent implements OnInit, OnDestroy {
  id: number;
  ticketDetail: any;
  payment_details: any

  constructor(private route: ActivatedRoute, private router: Router, public service: MainService) {
    this.route.params.subscribe((params) => {
      this.id = params.id;
    });
  }

  ngOnInit(): void {
    // this.viewTicket();
    // this.showUserDetail()
    this.payment_details = JSON.parse(localStorage.getItem('payment_Details'));
    console.log(this.payment_details);

  }
  
  // reply(){
  //   let a = document.createElement('a')
  //   a.href = `mailto:${this.ticketDetail.email}?body=Hello! ${this.ticketDetail.firstName}`
  //   a.click()
  // }
  userDetail: any
  
  back() {
    localStorage.removeItem('payment_Details')
    this.router.navigate(['/payment-list'])
  }

  ngOnDestroy(): void {
    localStorage.removeItem('payment_Details')
  }

}
