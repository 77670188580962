import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { element } from "protractor";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { countryJson } from 'src/assets/country';
@Component({
  selector: "app-kyc-update",
  templateUrl: "./kyc-update.component.html",
  styleUrls: ["./kyc-update.component.css"],
})
export class KycUpdateComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  kycList: any = [];
  userId: any;
  url: string;
  kycAmountLimit: any;
  totalRecords: any;
  itemsPerPage: number = 10;

  text: any = false;
  isAssending: boolean = true;
  permissionArr : any = {}
  sortType : any = "DESC"
  type : any = "createdAt"
  today = new Date().toISOString().split('T')[0]
  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe,
    private httpClient: HttpClient
  ) {

    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
   }

  ngOnInit(): void {
    this.kycForm = new FormGroup({
      status: new FormControl("", Validators.required),
      userName: new FormControl("", Validators.required),
      country: new FormControl(""),
      startdate: new FormControl(""),
      enddate: new FormControl("")
    });
    this.kyc();
    // console.log(this.text);

    this.countryList();
  }

  products: any = [];
  countryList() {
    let obj = new countryJson()
    this.products = obj.country
  }

  // api of kyc listing
  kyc() {
    // var url =
    //   "account/admin/kyc-management/filter-kyc-users-list?page=" +
    //   (this.pageNumber - 1) +
    //   "&pageSize=10";
    let url = `kyc/kycList`;
    const data = {
      page :this.pageNumber,
      limit : "10",
      search :this.kycForm.value.userName,
      kycStatus : this.kycForm.value.status,
      country : this.kycForm.value.country,
      sortKey : this.type,
      sortType : this.sortType,
      fromDate : this.kycForm.value.startdate ? new Date(this.kycForm.value.startdate).toISOString() : '',
      toDate : this.kycForm.value.enddate ? new Date(this.kycForm.value.enddate).toISOString() : ''
    }
    this.service.showSpinner()
    this.service.post(url,data).subscribe(
      (res: any) => {
        // console.log("kyc", res);
        if (res.status == 200) {
          this.kycList = res.data.docs;

        }
        this.totalRecords = res.data.total;
        this.service.hideSpinner();

      },
      (err) => {
        if (err["status"] == 401) {
          this.service.hideSpinner();

          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.hideSpinner();

          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  kycRequiredCheckData: any;


  KycStatusCheck: any;
  // get kyc check status
 


  search() {

    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber - 1}&pageSize=10${this.kycForm.value.userName ? "&search=" + this.kycForm.value.userName : ""}${this.kycForm.value.status ? "&kycStatus=" + this.kycForm.value.status : ""}${this.kycForm.value.country ? "&country=" + this.kycForm.value.country : ""}`;
    this.isSearched = true
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        this.kycList = res.data.list;
        // this.totalRecords = res.data.totalCount;
        this.totalRecords = res.data.totalCount;
      },
      (err) => {
        this.service.hideSpinner();
        // this.listing = [];

        // this.totalRecords = err.data.totalCount;
      }
    );
  }


  // reset
  reset() {
    this.pageNumber = 1
    // this.isSearched = false
    // if (
    //   this.kycForm.value.userName || this.kycForm.value.status || this.kycForm.value.country
    // ) {
    //   this.kycForm.reset({
    //     userName: "",
    //     // status: '',
    //     status: "",
    //     country: "",
    //   });
    //   this.kyc();
    // }
          this.kycForm.reset({
        userName: "",
       
        status: "",
        country: "",
        startdate : "",
        enddate : ""
      });
    this.kyc();
  }

  view(id) {
    // console.log("gg", id);
    this.route.navigate(["/kyc-detail"], { queryParams: { id: id } });
  }

  exportAsXLSX() {
    let dataArr = [];
    this.kycList.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        Username: element.userId.firstName ? element.userId.firstName + ' ' +element.userId.lastName : "N/A",
        Email: element.userId.email ? element.userId.email : "N/A",
        "Mobile Number": element.userId.phoneN0 ? element.userId.phoneN0 : "N/A",
        "Document Name": element.documentName ? element.documentName : "N/A",
        "Document ID": element.documentNumber ? element.documentNumber : "N/A",
        "Date & Time": element.updatedAt ? element.updatedAt.slice(0,10) : "N/A",
        // "Date & Time": element.updateTime ? this.transform.transform(element.updateTime, 'medium') : 'N/A',
        Status: element.kycStatus ? element.kycStatus : "N/A",
      });
    });
    // console.log(dataArr);
    this.service.exportAsExcelFile(dataArr, "Kyc List "+ this.today);
  }
  sortAgent(key) {
    this.type = key
    this.sortType = this.sortType == 'ASC' ? 'DESC' : 'ASC'
    this.kyc()
  }
  isSearched: boolean = false
  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    this.kyc()
   

  }
 
}
