import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
declare var $: any;

@Component({
  selector: 'app-withdrawal-token-management',
  templateUrl: './withdrawal-token-management.component.html',
  styleUrls: ['./withdrawal-token-management.component.css']
})


export class WithdrawalTokenManagementComponent implements OnInit {
  @Input() userId: any
  userForm: FormGroup;
  currTab: any = "Withdraw";
  withdrawlData: any = [];
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  depositeData: any = [];
  totalRecords: any
  coin: any;
  coinName: any;
  coinData: any = [];
  url: string;
  userDetail:any
  walletBalance: any = [];
  isSearched: boolean = false;
  id: any;
  documentId: any;
  permissionArr : any = {}
  kycId: any;
  today: any = new Date().toISOString().split('T')

  
  sortType : any = "DESC"
  type : any = "createdAt"
  constructor(private router: Router, public service: MainService, private route: ActivatedRoute,private date: DatePipe) {
    this.route.params.subscribe((params) => {
      this.id = params.id

    })
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit(): void {
    // this.withdrawl();
    this.userForm = new FormGroup({
      startdate: new FormControl(""),
      enddate: new FormControl(""),
      searchText: new FormControl(""),
      country: new FormControl(""),
    });
    this.coinList()
    this.myProfile();
    // this.getClaimData();
    this.countryList();
    this.getTotalWithdrawalAmount();
  }
   products: any = [];
  countryList() {
    let obj = new countryJson()
    this.products = obj.country
  }

  sortAgent(key) {
    this.type = key
    this.sortType = this.sortType == 'ASC' ? 'DESC' : 'ASC'
    this.coinList()
  }
  

  coinList() {

    let startdate = this.userForm.value.startdate;

    let enddate = this.userForm.value.enddate;
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let url = `wallet/transactions?page=${this.pageNumber}&pageSize=${this.itemsPerPage}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}&txnType=WITHDRAW`;
    url += `&sortKey=${this.type}&sortType=${this.sortType}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinData = res['data']['docs']
        this.totalRecords = res['data']['total']
      }else{
        this.coinData = []

      }

    }, (err) => {
      this.coinData = []
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr(err.error.message);
      }
    })

  }

  // Api of search 
  search() {
    this.coinName = this.userForm.value.select;
    // let fromDate=Date.parse(this.userForm.value.startdate);
    let fromDate = Date.parse(this.userForm.value.startdate);
    let currentTime = new Date().getTime()
    console.log("hdh", fromDate);

    let toDate = Date.parse(this.userForm.value.enddate);
    console.log('jhhj', toDate + currentTime, currentTime);

    if (this.userForm.value.enddate && this.coinName && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=WITHDRAW"
    }

    else if (this.userForm.value.enddate && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=WITHDRAW";
    }

    else if (this.userForm.value.startdate && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&txnType=WITHDRAW";
    }

    else if (this.coinName && this.currTab === 'Withdraw') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&txnType=WITHDRAW";
    }

    else if (this.userForm.value.enddate && this.coinName && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=DEPOSIT"
    }
    else if (this.userForm.value.enddate && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&toDate=" + toDate + "&txnType=DEPOSIT";
    }

    else if (this.userForm.value.startdate && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&fromDate=" + fromDate + "&txnType=DEPOSIT";
    }

    else if (this.coinName && this.currTab === 'Deposite') {
      this.url = "wallet/admin/transaction-history/get-all-transaction-history?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&coinName=" + this.coinName + "&txnType=DEPOSIT";
    }





    this.service.get(this.url).subscribe((res: any) => {
      console.log('search', res);

      if (res['status'] == 200) {
        this.service.toasterSucc(res.message);
        // this.withdrawlData=res['data']['resultlist']
        this.coinData = res['data']['resultlist']
        this.totalRecords = res['data']['totalCount']
      }else{
        this.coinData = []

      }

    }, (err) => {
      this.coinData = []
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr(err.error.message);
      }
    })
    this.service.hideSpinner();
  }

  // reset data of search
  reset() {
    // if (
    //   this.userForm.value.startdate ||
    //   this.userForm.value.enddate ||
    //   this.userForm.value.searchText
    // ) {
    //   this.userForm.reset({
    //     startdate: "",
    //     enddate: "",
    //     searchText: "",
    //     country: "",
    //   });
    //   this.coinList();
    // }
    this.userForm.reset({
      startdate: "",
      enddate: "",
      searchText: "",
      country: "",
    });
    this.coinList();
  }

  //export User
  async exportAsXLSX() {
    let temp = [];
    let startdate = this.userForm.value.startdate;

    let enddate = this.userForm.value.enddate;
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let url = `wallet/transactions?page=${this.pageNumber}&pageSize=${this.totalRecords}${this.userForm.value.searchText ? "&userEmail=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}&txnType=WITHDRAW`;
    url += `&sortKey=${this.type}&sortType=${this.sortType}`
    this.service.showSpinner();
    try {
      let res: any = await this.service.get(url).toPromise();

      this.service.hideSpinner();
      if (res.status == 200) {
        let dataArr = [];
        res.data.docs.forEach((element, ind) => {
         

          dataArr.push({
            "S.No.": ind + 1,
            "Username": element.userId.firstName + " " + element.userId.firstName ? element.userId.firstName + " " + element.userId.firstName : "N/A",
            "Email Id": element.userId.email ? element.userId.email : "N/A",
            "Fiero Amount": element.buyAmount ? element.buyAmount : "N/A",
            "Fiero Price": element.lastFieroPrice ? element.lastFieroPrice : "N/A",
            "Claim Address": element.userId.withdrawAddress ? element.userId.withdrawAddress : "N/A",
            "Transaction Hash": element.transactionHash ? element.transactionHash : "N/A",
            
            "Txn. Time": element.createdAt ? this.date.transform(element.createdAt, 'medium') : "N/A",
            "Status": element.transactionStatus ? element.transactionStatus : "N/A",
          });
        });

        this.service.exportAsExcelFile(dataArr, "Token Claim Transaction list "+ this.today);
      }
    } catch (error) {
      console.log(error)
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }


  }



  // wallet balance
  // api of Trading
 
  
  pagination(page) {
    this.pageNumber = page;
      this.coinList();
  }
  tokenId: any
  approved(id) {
    this.tokenId = id
    $("#approveModal").modal("show");
  }
  txnArray : any
  transactionHash(arr) {
    this.txnArray = arr
    console.log(arr);
    
    $("#txnModal").modal("show");
  }
  approveKyc() {
    this.service.showSpinner();
    const url = `wallet/vesting-management/admin/claim-token?tokenRequest=CONFIRM&txnId=${this.tokenId}`
    this.service.put(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc("Accepted successfully.");
        $("#approveModal").modal("hide");
        this.coinList();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  // My Profile Functionality
  myProfile() {
    var url = 'admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data']['role']
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  shortAddress(address) {
    const shortedValue = `${address.slice(0, 6)}....${address.slice(address.length - 4)}`
    return shortedValue
  }
  copyToClipboard(item) {
    navigator.clipboard.writeText(item).then(
      () => {
        this.service.toasterSucc('Copied')
      },
      () => {
        this.service.toasterErr('Failed')
      }
    );
  }
  userDetailData:any
  functionNew(){
    return this.service.toasterErr('This feature is only capable for admin!')
  }
  getClaimData(){
    let url = `wallet/vesting-management/admin/get-claim-data`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetailData = res['data']
      } else {
        // this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        // this.service.toasterErr(err.error.message);
      }
    })
  }
  userBalance:any
  getTotalWithdrawalAmount(){
    var url = 'wallet/totalWithdrawAmount';
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.userBalance = res['data']
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  getNewTrnasatctionHash(newTransactionHash){
    const inputString = newTransactionHash;

    // Replace "=" with ":" and add double quotes around keys
    const correctedString = inputString
      .replace(/(\w+)(?==)/g, '"$1"')
      .replace(/=/g, ':');
    
    console.log(JSON.stringify(correctedString));
    let myjs = JSON.stringify(correctedString)
   
    let  parseData = JSON.parse(myjs)
    console.log( parseData);
    
    return parseData

  }
  isValidDate(dateString) {
    // Try creating a new Date object from the given string
    const date = new Date(dateString);
    
    // Check if the date is valid and not equal to "Invalid Date"
    // Also, make sure the input string is not the same as the output string
    return date.toString() !== "Invalid Date" && date.toString() !== dateString;
}
}

