<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">VIEW TICKET </h1>
        </div>

        <!-- tab General -->
        <div class="tab-pane active show">
            <div class="order-view mt30">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Id</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{ticketDetail?._id}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Customer Name</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{ticketDetail?.name}}</label>
                            </div>

                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Email</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label class="user-eml">{{ticketDetail?.email}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Message</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label style="word-break: break-word;">{{ticketDetail?.description}}</label>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Date & Time</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label class="user-eml">{{ticketDetail?.createdAt | date :'medium'}}</label>
                            </div>
                        </div>








                    </div>
                    <div class="text-left mt40 text-center">
                        <a routerLink="/ticket-management" class="btn btn-large  max-WT-200 font-100 btn-green" style="    background: #333;
                        color: #fff !important;
                        border-radius: 50px !important;
                        font-size: 16px !important;">Back</a>
                        <a (click)="reply()" class="btn btn-large  max-WT-200 font-100 btn-green ml-2" style="color: #fff !important;
                        background: #302F35;
                        border-radius: 3px !important;
                        font-size: 16px !important;
                        color: #fff;
                        padding: 10px 35px;
                        font-size: 14px;
                        background: linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%);
                        font-weight: 500;
                        line-height: 21px;
                        height: 48px;
                        border-radius: 50px;
                        border-radius: 50px !important;">Reply</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- tab genral end -->


        <!-- tab login session end -->

    </div>
</main>
<footer style="margin-top: 8%;">
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>