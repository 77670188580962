import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-news-letter-list',
  templateUrl: './news-letter-list.component.html',
  styleUrls: ['./news-letter-list.component.css']
})
export class NewsLetterListComponent implements OnInit {
  advertisementList=[]
  itemsPerPage=10
  currentPage=1
  totalItems:any
  advertisementId: any;
  myid: any;
  total: any;
  id: any;
  status: any;
  isActive : any = []
  constructor( private router: Router, public service: MainService) { }

  ngOnInit(): void {
    this.getAdvertisementList();
  }

  //-----pagination-----//
  pagination(event) {
    this.currentPage = event   
  }

  //-----get list of advertisement-----//
  getAdvertisementList() {
    // let temp = {image : "assets/img/alende.png", status : 'Active', startDate : new Date(),endDate : new Date(),id : 1}
    // for(let i = 0 ; i <35 ; i++){
    //   this.advertisementList[i] = temp
    // }
    // this.total = 35
    
    let i=0
    let url = "account/get-all-news-letter-for-website"
    this.service.showSpinner()
    this.service.get(url).subscribe((res)=>{
      if(res["status"] == 200){
        this.advertisementList = res["data"]['details'].filter((ele)=>{
          return !ele.isDeleted
        })
        // for (let item of res["data"]['details']) {
        //   if (item.status == "ACTIVE") {
        //     this.isActive[i] = true
        //   } 
        //   if (item.status == "BLOCK")
        //   {
        //     this.isActive[i] = false
        //   }
         
        //  i++
        // }
        this.total=this.advertisementList.length
        this.service.hideSpinner()
        this.service.toasterSucc(res["responseMessage"])
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr(res["responseMessage"])
      }
    },(err)=>{
      this.advertisementList=[]
      this.total=0
      this.service.hideSpinner()

    })
  }
  editAdvertisement(advertisementId) {
    console.log('id', advertisementId);
    this.router.navigate(['/news-letter-edit'], { queryParams: { newsId: advertisementId } })
  }
  viewAdvertisement(advertisementId) {
    console.log('id', advertisementId);
    this.router.navigate(['/news-letter-view'], { queryParams: { newsId: advertisementId } })
  }

    //-----delete individual data of advertisement-----//
  openDeleteBlogModal(advertisementId) {
    $('#deleteBlog').modal('show')
    this.advertisementId = advertisementId
  }
  deleteAdvertisement() {
    let url = `account/delete-news-letter?newsLetterId=${this.advertisementId}`
    this.service.showSpinner();
    this.service.postApi(url, {}).subscribe((res: any) => {
      $('#deleteBlog').modal('hide');
      if (res["status"] == 200) {
        this.getAdvertisementList()
        this.service.toasterSucc(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res["responseMessage"])
      }
    })
  }
  
  //------ Active and block advertisement-----//
  changeBannerStatus(status,id){
    let advertisementStatus = status
    if(status){
      advertisementStatus = "ACTIVE"
      this.getActive(advertisementStatus,id)
    }
    else{
      advertisementStatus = "BLOCK"
      this. getBlock(advertisementStatus,id)
    }
  }
  getActive(advertisementStatus,id){
      let url = "api/v1/admin/blockUnblockAdvertisement?id="+id
      const data = {
        
        "id": id,
          "status" : advertisementStatus,
        
      }
      this.service.postApi(url,data).subscribe((res)=>{
        if (res["status"] ==200) {
          this.service.hideSpinner()
          this.service.toasterSucc(res["responseMessage"])
        } else {
          this.service.hideSpinner()
          this.service.toasterErr(res["responseMessage"])
        }
      })
    }
    
    getBlock(advertisementStatus,id){
        let url = "api/v1/admin/blockUnblockAdvertisement?id="+id
        const data = {
         
          "id": id,
          "status" : advertisementStatus,
           
        }
        this.service.postApi(url,data).subscribe((res)=>{
          if (res["status"]==200) {
            this.service.hideSpinner()
            this.service.toasterSucc(res["responseMessage"])
          } else {
            this.service.hideSpinner()
            this.service.toasterErr(res["responseMessage"])
          }
        })
      } 

}
