import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-whitepaper-edit',
  templateUrl: './whitepaper-edit.component.html',
  styleUrls: ['./whitepaper-edit.component.css']
})
export class WhitepaperEditComponent implements OnInit {
  addSwatchesForm: FormGroup
  imageUrl
  imageUrl1:any
  imageUrl2:any
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  whitepaperId: any;
  currTab: any;

  constructor(private router: Router, public service: MainService, public active:ActivatedRoute,public sanitizer : DomSanitizer) {
    this.active.queryParams.subscribe((res:any)=>{
      this.whitepaperId=res.whitepaperId;
     
    })
   }

  ngOnInit() {
    this.addSwatchesFormValidation();
    // this.getColorList()
    this.viewWhitePaper();
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      // 'colorName': new FormControl('', [Validators.required]),
      'language': new FormControl('', [Validators.required]),
      // 'description': new FormControl('',[Validators.required]),
      // 'file': new FormControl(''),
      'image': new FormControl(''),
      'whitepaperImage':new FormControl(''),
      'whitepaperImageArabic':new FormControl('')


    })
  }

  /**
   * @call validation 
   */
  // get colorName() { return this.addSwatchesForm.get('colorName') }
  // get body() { return this.addSwatchesForm.get('body') }
  // get description() { return this.addSwatchesForm.get('description') }

  // get price() { return this.addSwatchesForm.get('price') }
  // get quantity() { return this.addSwatchesForm.get('quantity') }
 


  addWhitePaper() {
    // console.log(this.colorArray);
    // this.router.navigate(['/swatches/list-swatches'])
    let data = {
      'file' : this.imageUrl,
      // 'file1': this.imageUrl1,
      // 'file2': this.imageUrl2,
      'language' : this.addSwatchesForm.value.language,
      'whitepaperId' : this.whitepaperId
    }
    this.service.showSpinner();
    this.service.postApi('account/Update-whitepaper', data).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.service.toasterSucc(res.message);
        this.service.hideSpinner();
        this.router.navigate(['/whitepaper-list'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    } , (err)=>{
      this.service.hideSpinner()
     })
  }

  viewWhitePaper(){
    let url = `account/get-details-by-whitepaperId?whitepaperId=${this.whitepaperId}`
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.service.toasterSucc(res.message);
        this.addSwatchesForm.patchValue({
          "language" : res?.data?.language
        })
        this.imageUrl = res?.data?.file
        // this.imageUrl1 = res?.data?.file1
        // this.imageUrl2 = res?.data?.file2

        this.service.hideSpinner();
        
        // this.router.navigate(['/whitepaper-list'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    },
    (err)=>{
      this.service.hideSpinner()
     })
  }

  

  // uploadImg(event): void {
  //   var img = event.target.files[0];
  //   this.uploadImageFunc(img);
  // }
  // uploadImageFunc(img) {
  //   var fb = new FormData();
  //   fb.append('file', img)
  //   this.service.showSpinner();
  //   this.service.postApi('account/upload-file', fb).subscribe((res: any) => {
  //     console.log(res);
  //     if (res.status == 200) {

  //       this.imageUrl = res['data'];
  //       this.imageUrl1 = res['data'];
  //       this.imageUrl2 = res['data'];

  //       this.service.hideSpinner();
  //     } else {
  //       this.service.hideSpinner();
  //     }
  //   },(err)=>{
  //    this.service.hideSpinner()
  //   }
  //   )
  // }

// upload file image
uploadImg(event,homeImage): void {
  var img = event.target.files[0];
  this.uploadImageFunc(img,homeImage);
}
uploadImageFunc(img,homeImage) {
  var fb = new FormData();
  fb.append('file', img)
  this.service.showSpinner();
  this.service.post('account/upload-file', fb).subscribe((res: any) => {
    console.log(res);
    if (res.status == 200) {
      // this.imageUrl = res['result']['secure_url'];
      // this.imageUrl = res['result']
     
      if(homeImage == 'ENGLISH'){

        this.imageUrl = res['data'];
      }else if(homeImage == 'FRANCE'){

        this.imageUrl1 = res['data'];
      }else{
        this.imageUrl2 = res['data'];

      }
      this.service.hideSpinner();

    } else {
      this.service.hideSpinner();
      // this.imageUrl = res['result']['mediaUrl'];
    }
  },(err)=>{
       this.service.hideSpinner()
      }
  )
}



remove(e){
  this.imageUrl = ''
  if(e == 1){
    this.addSwatchesForm.get('image').reset()
  }
  else if(e == 2){
    this.addSwatchesForm.get('whitepaperImage').reset()
  }
  else if(e == 3){
    this.addSwatchesForm.get('whitepaperImageArabic').reset()

  }
}
  // async uploadColorFunc(img, i) {
  //   var fb = new FormData();
  //   fb.append('file', img.target.files[0])
  //   this.service.showSpinner();
  //   let respose = await this.service.postApi('ApiEndPoint.uploadFile', fb).toPromise()
  //   console.log(this.colorArray);
  //   this.service.hideSpinner();

  //   this.colorArray[i] = {
  //     'colorId': this.colorArray[i]['colorId'],
  //     'image': {webImage : respose['result']['webImage'], mobileImage : respose['result']['mobileImage'] }
  //   }

  // }
  // colorFieldArray: any = [1]
  // colorArray: any = []
  // addColour() {
  //   this.colorFieldArray.push(1)
  // }
  // showImage(event, i) {

  //   this.colorArray[i] = {
  //     'colorId': event.target.value,
  //     'image': ''
  //   }
  //   console.log(this.showImageField[i]);

  //   this.showImageField[i] = true;
  //   console.log(this.showImageField[i]);

  // }
}
