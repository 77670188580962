import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any
@Component({
  selector: 'ip-whitelist',
  templateUrl: './ip-whitelist.component.html',
  styleUrls: ['./ip-whitelist.component.css']
})
export class IpWhitelistComponent implements OnInit {
  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;

  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)
  ipdAddress = new FormControl('', [
    Validators.required,
    Validators.pattern('^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
                      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
                      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
                      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
  ]);
  permissionArr: any = {}
  constructor(
    private router: Router, public service: MainService
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
    this.getIpAddrress()
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getTicketList();
    this.getIpStatus();
  }
  status:any
  getIpStatus() {
    this.service.showSpinner();
    this.service.get(`admin/getWhiteListIpAddressStatus`).subscribe((res: any) => {
      if(res.status==205){
        this.enableDisable("ENABLE")
      }
      this.status=res.data.status
    })
    this.service.hideSpinner()
  }

  getTicketList() {


    this.service.showSpinner();
    this.service.get(`admin/listIpAddress?page=${this.pageNumber}&limit=${this.limit}`).subscribe((res: any) => {
      this.ticketList = res.data.docs
      this.totalRecords = res.data.total;
    })
    this.service.hideSpinner()
  }

  openAddModal() {
    $('#addModal').modal('show')
  }
  deleteId: any
  openDeleteModal(id) {
    this.deleteId = id
    $('#deleteModal').modal('show')
  }

  editIp = new FormControl('', [
    Validators.required,
    Validators.pattern('^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
                      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
                      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.' +
                      '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
  ]);
  editID: any
  openEditModal(ip, id) {
    this.editID = id
    this.editIp.setValue(ip) 
    $('#editModal').modal('show')
  }
  onadd() {

    let request = {
      "ipAddress": this.ipdAddress.value
    }
    this.service.showSpinner();
    this.service.post(`admin/addIpAddress`, request).subscribe((res: any) => {
      $('#addModal').modal('hide')
      this.getTicketList();
    }, (err: any)=>{
      this.service.toasterErr(err.error.message)
    })
    this.service.hideSpinner()
  }
  onedit() {


    this.service.showSpinner();
    const obj = {
      _id : this.editID,
      ipAddress : this.editIp.value
    }
    this.service.put(`admin/editIpAddress`,obj).subscribe((res: any) => {
      $('#editModal').modal('hide')
      this.getTicketList();
    })
    this.service.hideSpinner()
  }
  blockType: any
  blockId: any
  statusType:any
  block(type, id) {
    this.blockId = id
    this.blockType = type
    if (type == 'ENABLE') {
      $('#disableip').modal('show')

    }
    else {
      $('#enableip').modal('show')
    }
  }
  enableDisable(type) {
    this.statusType = type
    if (type == 'ACTIVE') {
      $('#Alldisableip').modal('show')

    }
    else {
      $('#Allenableip').modal('show')
    }
  }
  onDelete() {


    this.service.showSpinner();
    this.service.delete(`admin/deleteIpAddress?_id=${this.deleteId}`).subscribe((res: any) => {
      $('#deleteModal').modal('hide')
      this.getTicketList();
    })
    this.service.hideSpinner()
  }
  onEnableDisable() {
    this.service.showSpinner();
    this.service.put(`admin/editWhiteListIpAddressStatus`,{}).subscribe((res: any) => {
      $('#Allenableip').modal('hide')
      $('#Alldisableip').modal('hide')
     
      this.getIpStatus();
    })
    this.service.hideSpinner()
  }

  ip : any
  async getIpAddrress() {

    await fetch(`https://api.ipify.org/?format=json`).then(res => res.json()).then((data: any) => {
      console.log(data)
      this.ip = data.ip
      

    })
  }
  enableip() {
    this.service.showSpinner();

    this.service.get(`admin/enableDisableIpAddress?_id=${this.blockId}`).subscribe((res: any) => {
      $('#enableip').modal('hide')
      $('#disableip').modal('hide')
      this.getTicketList();
    })
    this.service.hideSpinner()
  }

  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    
      this.getTicketList();
    
  }
  

  // reset
  // reset(){
  //   this.userForm.reset()
  //   this.getTicketList(this.page)
  // }

 
  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.ticketList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Ticket ID": element.ticketId ? element.ticketId : '',
        "Message": element?.description ? element?.description : '',
        "Email": element.email ? element.email : 'N/A',
        "Customer Name": element.firstName + '' + element.lastName ? element.firstName + " " + (element.lastName ? element.lastName : '') : '',
        "Date": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
        "Status": element.ticketStatus,
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Queries Management');
    console.log(dataArr)
  }
  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}

