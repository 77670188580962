import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

declare var $
@Component({
  selector: 'app-fieres-tutorial-list',
  templateUrl: './fieres-tutorial-list.component.html',
  styleUrls: ['./fieres-tutorial-list.component.css']
})
export class FieresTutorialListComponent implements OnInit {
  currTab: any = 'English';
  faqData: any = [];
  pageNumber: number = 1
  faqId: any;
  isPublished: any;
  lan: any;
  totalRecords: any;
  itemsPerPage: number = 10;
  permissionArr: any = {}
  selectedLanguage = ''
  languageArrs = languagesArray
  constructor(public route: Router, public service: MainService) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit(): void {
    this.faqList()
  }

  // tab function

  // faq english
  faqList() {
    
    var url = "static/academyList?page=" + (this.pageNumber) + "&limit=10" + `${this.selectedLanguage ? '&language=' + this.selectedLanguage : ''}`;
    url += `&sortKey=${this.type}&sortType=${this.sortType}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      console.log("kyc", res);
      if (res.status == 200) {
        this.faqData = res.data.docs;
        this.totalRecords = res.data.total;

      }

      this.service.hideSpinner()

    }, (err) => {
      if (err['status'] == 401) {
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.hideSpinner()
        this.service.toasterErr(err.error.message);
      }
    })
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.faqList();
    } else {
      this.faqList();
    }
  }
 
  // add faq
  addFaq() {
    this.route.navigate(['/fieres-tutorial-add'])
  }

  // edit faq
  editFaq(faqId) {
    this.route.navigate(['/fieres-tutorial-edit'], { queryParams: { faqId: faqId } })
  }

  viewFaq(faqId) {
    this.route.navigate(['/fieres-tutorial-view'], { queryParams: { faqId: faqId } })
  }
  // open modal
  delete(faqId) {
    this.faqId = faqId;
    $('#deleteModal').modal('show')
  }


  // delete functionallity of faq english
  deleteUser() {
    let url = "static/deleteAcademy?_id=" + this.faqId;
    this.service.showSpinner();
    this.service.delete(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#deleteModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr(err.error.message);
      }
    })
  }


  edit() { }
  view() { }

  isAssending: boolean = true;
  sortType = 'ASC'
  type = 'createdAt'

  sortAgent(key) {
    this.type = key
    this.sortType = this.sortType == 'ASC' ? 'DESC' : 'ASC'
    this.faqList()
  }

}
const languagesArray = [
  { "code": "fr", "name": "French", "nativeName": "français, langue française" },
  { "code": "it", "name": "Italian", "nativeName": "Italiano" },
  { "code": "en", "name": "English", "nativeName": "English" },
  { "code": "es", "name": "Spanish; Castilian", "nativeName": "español, castellano" }
]
