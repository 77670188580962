import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  aboutUs: any;
  description: any;
  currUrl: any;
  title: any;
  permissionArr : any = {}
  constructor(public service: MainService, public route: Router, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((params) => {
      this.currUrl = params.page
      console.log('jj', this.currUrl);

    })
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })

  }

  ngOnInit() {
    this.aboutus()
    // this.LanguageData();


  }

  // english about us
  aboutus() {
    this.service.get(`static/getStatic?type=${this.currUrl}`).subscribe((res: any) => {
      if (res.status == 200) {
        this.aboutUs = res.data
        this.title = this.aboutUs.title
        this.description = this.aboutUs.pageData
        console.log(res)
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  // update language
  updateLanguageData() {
    this.updateEnglishAboutUs()
    // if(this.currUrl=='English'){
    //   this.updateEnglishAboutUs()
    // }
    // else if(this.currUrl=='German'){
    //   this.updateGermanAboutUs()
    // }
    // else if(this.currUrl=='Spanish'){
    //   this.updateSpanishAboutUs()
    // }
    // 
  }

  // english
  updateEnglishAboutUs() {
    let request = {
      "_id" : this.aboutUs._id,
      'pageData': this.description,
      
    }
    this.service.put(`static/updateStatic`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/static-content-management'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })

  }

  // german
  updateGermanAboutUs() {
    let request = {
      'pageData': this.description,
      'pageKey': this.title,
    }
    this.service.post(`static/update-german-static-content-data`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/static-content-management'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })

  }

  // spanish
  updateSpanishAboutUs() {
    let request = {
      'pageData': this.description,
      'pageKey': this.title,
    }
    this.service.post(`static/update-spanish-static-content-data`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/static-content-management'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })

  }

}
