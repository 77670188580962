<div
  class="login-wrapper"
  style="background-color: #000; position: relative; overflow: hidden"
>
  <div class="orangeshadeBox1"></div>
  <div class="purpleBox1"></div>
  <div class="container-common">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="d-flex align-items-center minheight70vh">
          <form
            class="login_box_outer w-100"
            [formGroup]="loginForm"
            autocomplete="off"
          >
            <div class="login-box max-WT-520">
              <div class="login-right-block">
                <div class="text-center">
                  <img
                    src="assets\img\logo.png"
                    alt="crypto-logo"
                    class="data-img"
                    style="width: auto; max-width: 30vh"
                  />
                </div>
                <div class="login-heading">
                  <h4>Log in</h4>
                </div>
                <div class="login-box-body">
                  <div class="form-group">
                    <input
                      type="text"
                      formControlName="email"
                      (keypress)="service.preventSpace($event)"
                      id="loginPhoneNumber"
                      class="form-control"
                      placeholder="Email"
                      autocomplete="off"
                      [readonly]="true"
                      (focus)="disableReadonly($event)"
                      style="background: transparent; color: #fff"
                      name="new-password"
                    />
                    <div
                      class="text-danger"
                      *ngIf="
                        loginForm.get('email').hasError('required') &&
                        loginForm.get('email').touched
                      "
                    >
                      *Please enter email address.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="
                        loginForm.get('email').hasError('pattern') &&
                        loginForm.get('email').dirty
                      "
                    >
                      *Please enter valid email address.
                    </div>
                  </div>
                  <div class="form-group eyeicon">
                    <input
                      [type]="showPassOrText ? 'text' : 'password'"
                      maxlength="18"
                      formControlName="password"
                      autocomplete="off"
                      (keypress)="service.preventSpace($event)"
                      class="form-control"
                      placeholder="Password"
                      style="background: transparent; color: #fff"
                    />
                    <em
                      [class]="
                        showEyeOrCrossEye ? 'fa fa-eye' : 'fa fa-eye-slash'
                      "
                      aria-hidden="true"
                      (click)="showPassword()"
                      style="color: gray"
                    ></em>

                    <div
                      class="text-danger"
                      *ngIf="
                        loginForm.controls['password'].hasError('required') &&
                        loginForm.controls['password'].touched
                      "
                    >
                      *Please enter password.
                    </div>
                    <!--     <div class="text-danger"
                                                *ngIf="loginForm.get('password').hasError('minlength') && !(loginForm.get('password').hasError('pattern')) && loginForm.get('password').dirty">
                                                *Minimum length must be 8.
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="loginForm.get('password').hasError('maxlength') && !(loginForm.get('password').hasError('pattern')) && loginForm.get('password').dirty">
                                                *Maximum length should not more than 16.
                                            </div>
                                            <div class="text-danger" *ngIf="loginForm.get('password').hasError('pattern')">
                                                *Password should have minimum 8 characters, at
                                                least 1 uppercase letter, 1 lowercase letter, 1
                                                number and 1 special character.
                                            </div> -->
                  </div>

                  <div class="rememberme-section">
                    <label class="main"
                      >Remember me
                      <input
                        type="checkbox"
                        formControlName="rememberMe"
                        class="form-control"
                      />
                      <span class="geekmark"></span>
                    </label>
                    <label
                      class="checkbox-design"
                      style="
                        color: #ff0098;
                        cursor: pointer;
                        text-decoration: underline;
                      "
                    >
                      <span [routerLink]="['/forgot-password']"
                        >Forgot Password</span
                      >
                    </label>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    (click)="onLogin()"
                    [disabled]="!loginForm.valid"
                    class="btn btn-login btn-large width100 font-600"
                    style="margin-bottom: 20px"
                  >
                    LOGIN
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Google Auth Modal  -->
<div
  class="modal fade"
  id="googleAuth"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title text-white">
          Google Authentication Verification
        </h5>
        <button
          type="button"
          class="close bck-trns"
          data-dismiss="modal"
          aria-label="Close"
          (click)="removeToken()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="qrCode" class="qr-div mt-4 mb-3">
          <img [src]="qrCode" *ngIf="qrCode" alt="" />
        </div>
        <p *ngIf="secretKey" style="padding: 0px">
          Secret Key : {{ secretKey }}
          <i
            class="fa fa-clone"
            aria-hidden="true"
            style="cursor: pointer"
            (click)="copyToClipboard(secretKey)"
          ></i>
        </p>
        <div class="form-group search-div mt-3">
          <input
            type="address"
            class="form-control after-input new-input-field-custom-files-all"
            style="text-align: center"
            (keypress)="restrictSpace($event); restrictChar($event)"
            maxlength="6"
            placeholder="Enter the google code"
            [(ngModel)]="googleAuth"
          />
        </div>
        <div class="btn-modal text-center">
          <button
            class="btn btn-theme new-button-tab-custom"
            [disabled]="googleAuth.length != 6"
            (click)="verifyGoogleAuth()"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth Modal end  -->
