import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-set-commission',
  templateUrl: './set-commission.component.html',
  styleUrls: ['./set-commission.component.css']
})
export class SetCommissionComponent implements OnInit {

  coinList: any;
  pageNumber = 1;
  cointAmount: boolean = false
  commsiionValue: any
  constructor(public service: MainService) { }

  ngOnInit(): void {
    this.tradingFeeList()
  }

  tradingFeeList() {
    this.service.showSpinner();
    this.service.get('wallet/vesting-management/get-commision').subscribe((res: any) => {
      if (res.status = 200) {
        this.service.hideSpinner()
        this.coinList = res.data
        this.commsiionValue = this.coinList.data.adminComm
        this.cointAmount = false
      } else {
        this.service.hideSpinner()
      }
    }, err => {
      this.service.hideSpinner()
    })
  }
  enableInputField() {
    this.cointAmount = !this.cointAmount
  }

  updateCoinAmount(e) {
    if (!String(e).length) {


      this.service.toasterErr(`Please enter commission price`);

      return

    }
    let url = `wallet/vesting-management/admin/update-commision?commision=${e * 100}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message);
        this.tradingFeeList()
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner()
      }
    })
  }

}
