import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-add-ico',
  templateUrl: './add-ico.component.html',
  styleUrls: ['./add-ico.component.css']
})
export class AddICOComponent implements OnInit {
  coinList: any=[];
  pageNumber=1;
  cointAmount : any = []

  price : any = []
  startDate : any = []
  endDate : any = []
  supply : any = []
  usd : any = []
  fieroPrice : number
  priceType = ""
  finalPrice : any 


  markDownPercentage: any
  markUpPercentage : any
  twoFAStatus: string;
  qrCode: any;
  secretKey: any;
  ipAddress: any;
  googleAuth: any;
  userDetail : any
  towFaType : any
  constructor(public service:MainService) { }

  ngOnInit(): void {
    this.viewFee()
    this.withdrawlFeeList()
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
     this.myProfile()
    
  }
  myProfile() {
    var url = 'admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        
        this.towFaType = res['data']['twoFaType']
        
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  viewFee(){
    /* this.service.showSpinner(); */
    var url="wallet/coinDetail"
    this.service.showSpinner() 
    this.service.get(url).subscribe((res:any)=>{
      this.service.hideSpinner() 
      if(res.status==200){
       this.priceType = res.data.markStatus == 'ENABLE' ? 'MARK' : 'TRANCHES'
       this.markTab = res.data.markType
       this.percentageValue = this.markTab == 'UP' ? res.data.markUpPercentage : this.markTab == 'DOWN' ? res.data.markDownPercentage : 0
       this.markDownPercentage = res.data.markDownPercentage
       this.markUpPercentage = res.data.markUpPercentage
      }
    },(err)=>{
      if(err.status==401){
        this.service.hideSpinner();
        this.service.toasterErr('Unauthorized access')
      }
      else{
        this.service.toasterErr('something went wrong')
      }
    })
  }
 
  withdrawlFeeList(){

    
    let url = `vesting/getSaleList?page=${this.pageNumber - 1}&pageSize=${4}`

    // this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.coinList = res.data.docs;
        res.data.docs.forEach((element,index) => {
          this.cointAmount[index] = false 
          this.price[index] = element.fieroUsdPrice 
          this.startDate[index] = this.getDate(element.saleDate) 
          this.endDate[index] = this.getDate(element.saleUptodate) 
          this.supply[index] = element.totalQuantity
          this.usd[index] = element.price

          if(element.icoStatus == "ENABLE"){
            this.fieroPrice = Number(element.priceWithoutMark)
            this.finalPrice = element.price
          }
        });
      } 
      else {
        this.coinList = []
        this.service.hideSpinner();
      }
    },
      (err: any) => {
        this.coinList = []
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );


    
  }
  enableInputField(i){
    this.cointAmount.forEach((ele,index)=>{
      if(index == i){
        this.cointAmount[index] = true
      }
      else{
        this.cointAmount[index] = false
      }
    })
  }


  mfa = false
  twoFAStatusFun(index,id){
    this.params = {
      index, id
    }
    // if(this.twoFAStatus == 'NONE' || this.twoFAStatus == 'SKIP' ){
      this.updateCoinAmount(index, id)
    // }
    // else{
     
    //  this.mfa = true
    // }
  }
  mfa2 = false
  twoFAStatusFun2(){
    // this.setMarkupPercentage()
    this.params = {}
    if(this.twoFAStatus == 'NONE'  || this.twoFAStatus == 'SKIP'){
      this.setMarkupPercentage()
    }
    else{
      // $('#googleAuth').modal({ backdrop: 'static' });
     this.mfa2 = true
    }
  }
  params = {}
  async updateCoinAmount(index,id){
   
    let obj = {
      price : this.usd[index],
      saleDate : this.startDate[index],
      saleUptodate : this.endDate[index],
      fieroUsdPrice : this.price[index],
      totalQuantity : this.supply[index] 
    }
  
      let url = `vesting/updateSale?icoId=${id}`
      this.service.post(url,obj).subscribe((res: any) => {
        if (res.status == 200) {
         this.withdrawlFeeList()
         this.service.toasterSucc("Sale updated successfully")
        } 
        else {
          this.service.hideSpinner();
        }
      },
        (err: any) => {
          this.service.hideSpinner();
          this.service.toasterErr(err.error.message);
        }
      );
    
   

    
   
  }

 verfyAuth(params){
  this.updateCoinAmount(params.index, params.id)
 }


  blockUser(id, status) {
    let updatedStatus = status == 'ENABLE' ? 'DISABLE' : 'ENABLE'
    this.service.showSpinner();
    var url = "wallet/admin/disable-enable-ico?icoId=" + id + "&icoStatus=" + updatedStatus;
    this.service.showSpinner();
    this.service.post(url, {}).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.withdrawlFeeList()
        this.service.toasterSucc(res.message);
       
      } else {
        this.service.hideSpinner();
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message)
    }
    );
  }
  getDate(e){
    return new Date(e).toISOString().split('T')[0]
  }
  setTab(e){
    this.priceType = e
    this.updateMarkStatus()
  }
  updateMarkStatus(){
    var url=`wallet/updateMarkData?markStatus=${this.priceType == 'MARK' ? 'ENABLE' : 'DISABLE'}`
    this.service.showSpinner() 
    this.service.put(url,{}).subscribe((res:any)=>{
      this.service.hideSpinner() 
      this.viewFee()
    },(err)=>{
      if(err.status==401){
        this.service.hideSpinner();
        this.service.toasterErr('Unauthorized access')
      }
      else{
        this.service.toasterErr('something went wrong')
      }
    })
  }
  markTab = ''
  setMarkTab(e){
    this.markTab = e
    this.setFinalPrice(Number(this.markTab == 'UP' ? this.markUpPercentage : this.markDownPercentage))
  }
  percentageValue : any
  setMarkupPercentage(){
    let percentage = 0
    if(this.markTab == 'UP'){
      percentage = this.markUpPercentage
    }
    else if(this.markTab == 'DOWN'){
      percentage = this.markDownPercentage
    }
    let url = `wallet/updateMarkUpDown?markType=${this.markTab}&percentage=${percentage}`
    this.service.showSpinner() 
    this.service.put(url,{}).subscribe((res:any)=>{
      this.service.hideSpinner() 
      this.service.toasterSucc(res.message)
      this.withdrawlFeeList()
    },(err)=>{
      if(err.status==401){
        this.service.hideSpinner();
        this.service.toasterErr('Unauthorized access')
      }
      else{
        this.service.toasterErr('something went wrong')
      }
    })
  }
  setFinalPrice(e : number){
    e = e > 100 ? 100 : e < 0 ? 0 : e 
    if(this.markTab == 'UP'){
      this.finalPrice =  Number(this.fieroPrice + (this.fieroPrice * e / 100)).toFixed(2)
    }
    else if(this.markTab == 'DOWN'){
      this.finalPrice = Number(this.fieroPrice - (this.fieroPrice * e / 100)).toFixed(2)
    }
    else{
      this.finalPrice = this.fieroPrice 
    }
  }

  resetDate(i){
    this.endDate[i] = ""
  }
}
