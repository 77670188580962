import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any
@Component({
  selector: 'app-ticket-management',
  templateUrl: './ticket-management.component.html',
  styleUrls: ['./ticket-management.component.css']
})
export class TicketManagementComponent implements OnInit {
  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;

  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().split('T')[0]
  permissionArr: any = {}
  sortType: any = "DESC"
  type: any = "createdAt"
  constructor(
    private router: Router, public service: MainService
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit() {

    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getTicketList();
  }

  getTicketList() {
    this.page = this.page
    let startdate = this.userForm.value.startdate;
    let enddate = this.userForm.value.enddate;
    let search = this.userForm.controls.searchText.value;
    let status = this.userForm.controls.status.value;

    var request = {
      'fromDate': startdate,
      'search': search,
      'queryStatus': status,
      'toDate': enddate,
      'page': this.pageNumber,
      'limit': this.limit,
      'sortKey': this.type,
      'sortType': this.sortType
    }
    let data = this.service.removeEmptyKeys(request)


    this.service.showSpinner();
    this.service.post(`static/queryList`, data).subscribe((res: any) => {
      this.ticketList = res.data.docs
      this.totalRecords = res.data.total;
    })
    this.service.hideSpinner()
  }

  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];

    this.pageNumber = page;

    this.getTicketList();

  }
  updateTicketStatus(e, id: number) {
    let status = e.target.value
    id = id
    let data = {}
    this.service.put(`static/updateStatus?ticketId=${id}&queryStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.toasterSucc(res.message)
      }
    },(error)=>{
      if(error.status == 404){
        this.service.hideSpinner()
        
        this.service.toasterErr(error.error.message)
      }
      this.getTicketList();
    })
  }

  // reset
  // reset(){
  //   this.userForm.reset()
  //   this.getTicketList(this.page)
  // }
  reset() {
    // if (
    //   this.userForm.value.startdate ||
    //   this.userForm.value.enddate ||
    //   this.userForm.value.searchText ||
    //   this.userForm.value.status
    // ) {
    //   this.userForm.reset({
    //     startdate: "",
    //     // userStatus: "",
    //     enddate: "",
    //     searchText: "",
    //     status: "",
    //   });
    //   this.getTicketList();
    // }
    this.pageNumber = 1
    this.userForm.reset({
      startdate: "",
      // userStatus: "",
      enddate: "",
      searchText: "",
      status: "",
    });
    this.getTicketList();
  }
  replyTicket() {
    this.router.navigate(['/reply-ticket'])
  }
  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.ticketList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Ticket ID": element._id ? element._id : '',
        "Message": element?.description ? element?.description : '',
        "Email": element.email ? element.email : 'N/A',
        "Customer Name": element.name ? element.name : '',
        "Date": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
        "Status": element.queryStatus,
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Queries Management '+ this.today);
    console.log(dataArr)
  }
  // sorting
  sortAgent(key) {
    this.type = key
    this.sortType = this.sortType == 'ASC' ? 'DESC' : 'ASC'
    this.getTicketList()
  }
}

