import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';

declare var $
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit ,OnDestroy{
  loginForm: FormGroup;
  Obj: { 'email': any; };
  showPassOrText: boolean;
  showEyeOrCrossEye: boolean;
  qrCode: any = '';
  secretKey: any = '';
  googleAuth: any = '';
  ipAddress: string;
  interval : any
  // email: '[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,5}'
  constructor(private router: Router, private fb: FormBuilder, private http: HttpClient, public service: MainService) {
    this.interval =  setInterval(()=>{
      this.getIpAddrress()
      if(this.ip){
        clearInterval(this.interval)
      }
    },500)
   
   }

  ngOnInit() {
    this.checkInput()
    this.getIP()
  }
  getIP(){
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      // email: [remData.email, Validators.required, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,5})')],
      email: [remData.email, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]],

      password: [pass, [Validators.required]],
      rememberMe: (remData ? true : false)

    })
  }
  ip : any
  async getIpAddrress() {

    await fetch(`https://api.ipify.org/?format=json`).then(res => res.json()).then((data: any) => {
     
      this.ip = data.ip
      

    })
  }

  twoFAStatus:any
  userData : any
  onLogin() {
    this.service.showSpinner();
    this.service.post("admin/login", {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      deviceType: "WEB",
      ipAddress : this.ip ? this.ip :''
    }).subscribe(
      (res: any) => {

        this.service.hideSpinner();
        if (res['status'] == '200') {
          // localStorage.setItem('Auth', res['data']['token']);
          this.twoFAStatus = res['data']['TwoFa'];
          this.userData = res['data'];
          if (res['data']['TwoFa'] == 'NONE') {
            localStorage.setItem('Auth', res['data']['token']);
            this.service.changeLoginSub('login');
            window.location.reload()
            this.router.navigate(['dashboard']);
          } else if (res['data']['TwoFa'] == 'GOOGLE') {
            localStorage.setItem('data', res['data']['token']);
            $('#googleAuth').modal({ backdrop: 'static' });
            this.suggesstionFunc()
          } 
          else if (res['data']['TwoFa'] == 'SKIP') {
            localStorage.setItem('Auth', res['data']['token']);
            this.service.changeLoginSub('login');
            window.location.reload()
            this.router.navigate(['dashboard']);
          }
          if (this.loginForm.value.rememberMe == true) {
            let remData = {
              "email": this.loginForm.value.email,
              "password": window.btoa(this.loginForm.value.password)
            }
            localStorage.setItem('rememberMe', JSON.stringify(remData))

          }
          else{
            localStorage.removeItem('rememberMe')
          }

      
        }
      },
      (err: any) => {

        this.service.hideSpinner();
    
          this.service.toasterErr(err.error.message);
        
      }
    )
    this.Obj = {
      'email': this.loginForm.value.email,

    }

    localStorage.setItem('data', JSON.stringify(this.Obj));

  }
  showPassword() {
    this.showPassOrText = !this.showPassOrText;
    this.showEyeOrCrossEye = !this.showEyeOrCrossEye
  }
  // 2FA Suggestion Modal Functionality
  suggesstionFunc(){
      if (this.twoFAStatus == 'GOOGLE') {
        $('#googleAuth').modal('show');
      } else {
        this.service.showSpinner();
        this.service.get('account/google-auth').subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.qrCode = res['data']['qrCode'];
            this.secretKey = res['data']['secretKey'];
            $('#googleAuth').modal('show');
          }
        }, err => {
          this.service.hideSpinner();
        })
      }
  }

  // Verify Google / SMS Auth Functionality By Accounts
  verifyGoogleAuth() {
      var apireq = {};
      var url = '';
     
        url = 'user/verifyGoogleAuth';
        apireq = {
          "otp": this.googleAuth,
          //"secretKey": this.secretKey
        }
     
      this.service.showSpinner();
      this.service.postApi(url, apireq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          localStorage.removeItem('Auth')
          if (this.twoFAStatus == 'GOOGLE') {
            localStorage.setItem('Auth', this.userData['token']);
            this.service.changeLoginSub('login');
            sessionStorage.setItem('secretKey', this.secretKey);
          }
          this.service.toasterSucc('Google Authentication Verified');
          $('#googleAuth').modal('hide');
          // this.router.navigate(['']);
          this.router.navigate(['dashboard']);
        }
        else {
          this.service.toasterErr('Wrong Google Authentication Code');
          this.googleAuth = ''
        }
      }, err => {
        this.service.hideSpinner();
        
        this.service.toasterErr(err.error.message);
      })
  }
  removeToken() {
  }
    /** Function to restrict space */
    restrictSpace(event) {
      var k = event.charCode;
      if (k === 32) return false;
    }
  
    /** Function to restrict character */
    restrictChar(event) {
      var k = event.charCode;
      if (event.key === 'Backspace')
        k = 8;
      if (k >= 48 && k <= 57 || k == 8 || k == 46)
        return true;
      else
        return false;
    }
    copyToClipboard(item) {
      this.service.toasterSucc('Secret key copied')
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (item));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
    }
    disableReadonly(event: FocusEvent): void {
      const target = event.target as HTMLInputElement;
      target.readOnly = false;
    }
    ngOnDestroy(): void {
      clearInterval(this.interval)
    }
}

