import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
import { contractHelper } from 'src/assets/js/contractHelper';

declare var Web3
// import web3 from 'web3/lib/commonjs/web3.js';

// const Web3 = new web3(new web3.providers.HttpProvider("https://rpc2.fieroscan.com/"))
declare var $
declare let window: any;
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  countryList = obj.country
  userId: any
  loylity: FormGroup;
  userDetail: any;
  role: any;
  stateList : any = []
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute, private datePipe: DatePipe) {

    this.active.queryParams.subscribe((params) => {
      this.currTab = params.tab
      this.userId = params.userId
      if (params.userId) {
        this.showUserDetail()
      }
      console.log('jjf', this.currTab);

    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.getFormValue()
    this.myProfile()
  }
  getFormValue() {
    this.loylity = new FormGroup({
      loyalityAmount: new FormControl('')
    })
  }
  formValidation() {
    this.addFaqForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]{1,}$/)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$')]),
      'phoneNumber': new FormControl('', [Validators.required, Validators.pattern(/^(?![0\s-()+]*0+$)[- +()0-9]{8,16}$/)]),
      'emailId': new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      'country': new FormControl('', [Validators.required]),
      'postalCode': new FormControl('', [Validators.required, Validators.pattern(/^(?!0+$)[a-zA-Z0-9]{4,19}$/)]),
      'state': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]+[ ]?[A-Za-z]{1,}$/)]),
      'address': new FormControl('', [Validators.required]),
      'dob': new FormControl('', [Validators.required]),
      'ibiName': new FormControl('', [Validators.required]),
      'ibiId': new FormControl('', [Validators.required]),
      'withdrawAddress': new FormControl('', [Validators.required, Validators.pattern(/^(0x[a-fA-F0-9]{40}|NA)$/)]),
      'twofa': new FormControl('NONE'),
      // 'volumeAccount': new FormControl(''),
    })
  }
  towFaType
  // My Profile Functionality
  myProfile() {
    var url = 'admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.role = res['data'].role;
        this.towFaType = res['data']['twoFaType']
        if (this.role == "SUBADMIN") {
          this.addFaqForm.get('withdrawAddress').setValidators([])
          this.addFaqForm.get('withdrawAddress').updateValueAndValidity()
        }
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  userData : any
  showUserDetail() {
    this.service.showSpinner();
    var url = "admin/viewUser?userId=" + this.userId;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userData = res.data
          let dob = this.getDOB(res.data.dob)
          let dttt = new Date(dob).toISOString().split('T')[0]
          this.getStateList(res.data.country)
          this.addFaqForm.patchValue({
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            phoneNumber: res.data.phoneNo,
            emailId: String(res.data.email).toLowerCase(),
            country: res.data.country,
            postalCode: res.data.zipCode,
            state: res.data.state,
            address: res.data.address,
            dob: dttt,
            ibiName: res.data.ibiName,
            ibiId: res.data.ibiId,
            twofa: res.data.twoFAType,
            withdrawAddress: res.data.withdrawAddress,
            // volumeAccount: res.data.volumeAccount,
          })
         

          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  getDOB(t) {
    try {
      if (String(t).includes('-')) {
        return new Date(t).toISOString()
      }
      var utcSeconds = t;
      var d = new Date(0);
      d.setHours(12)
      d.setMinutes(0)
      d.setUTCSeconds(utcSeconds);
      return new Date(d).toISOString()
    } catch (error) {

      return new Date("1970-01-01").toISOString().split('T')[0]
    }

  }
  // add faq language
  addUser() {
    let body = {
      'address': this.addFaqForm.value.address,
      'country': this.addFaqForm.value.country,
      'dob': this.addFaqForm.value.dob,
      'email': this.addFaqForm.value.emailId,
      'firstName': this.addFaqForm.value.firstName,
      'ibiId': this.addFaqForm.value.ibiId,
      'ibiName': this.addFaqForm.value.ibiName,
      'lastName': this.addFaqForm.value.lastName,

      'phoneNo': this.addFaqForm.value.phoneNumber,

      'state': this.addFaqForm.value.state,
      'zipCode': this.addFaqForm.value.postalCode,
      'withdrawAddress': this.addFaqForm.value.withdrawAddress,
      // 'volumeAccount': this.addFaqForm.value.volumeAccount
    }
    console.log(body);

    this.service.post(`admin/addUser`, body).subscribe((res: any) => {
      console.log(res);

      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/user-management'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  updateUserData() {
    let body = {
      'address': this.addFaqForm.value.address,
      'country': this.addFaqForm.value.country,
      'dob': this.addFaqForm.value.dob,
      'email': this.addFaqForm.value.emailId,
      'firstName': this.addFaqForm.value.firstName,
      'ibiId': this.addFaqForm.value.ibiId,
      'ibiName': this.addFaqForm.value.ibiName,
      'lastName': this.addFaqForm.value.lastName,
      
      'phoneNo': this.addFaqForm.value.phoneNumber,
      // 'roleStatus': "USER",
      'state': this.addFaqForm.value.state,
      'zipCode': this.addFaqForm.value.postalCode,
      'twoFAType': this.addFaqForm.value.twofa,
      'withdrawAddress': this.addFaqForm.value.withdrawAddress,
      'userId': this.userId,
      // 'volumeAccount': this.addFaqForm.value.volumeAccount
    }
    const url = `admin/editUserProfile?otp=${this.googleAuthNumber}&password=${this.password}`
    this.service.put(url, body).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/user-management'])
        $('#editUserDetails').modal('hide')
        $('#updateVerification').modal('hide')

      } else {
        this.service.toasterSucc(res.message)
        $('#editUserDetails').modal('hide')
        $('#updateVerification').modal('hide')
      }
    }, err => {
      this.service.hideSpinner();
      $('#editUserDetails').modal('hide')
      $('#updateVerification').modal('hide')
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  addUserData() {
    this.addUser()
  }
  showData: any
  updateUserDataModal() {
    let body = {
      'address': this.addFaqForm.value.address,
      'country': this.addFaqForm.value.country,
      'dob': this.addFaqForm.value.dob,
      'email': this.addFaqForm.value.emailId,
      'firstName': this.addFaqForm.value.firstName,
      'ibiId': this.addFaqForm.value.ibiId,
      'ibiName': this.addFaqForm.value.ibiName,
      'lastName': this.addFaqForm.value.lastName,
      'password': "Password",
      'phoneNo': this.addFaqForm.value.phoneNumber,
      'roleStatus': "USER",
      'state': this.addFaqForm.value.state,
      'zipCode': this.addFaqForm.value.postalCode,
      'twoFaType': this.addFaqForm.value.twofa,
      'withdrawAddress': this.addFaqForm.value.withdrawAddress,
      // 'volumeAccount': this.addFaqForm.value.volumeAccount
    }
    this.showData = body
    setTimeout(() => {
      $('#editUserDetails').modal('show')

    }, 1000);


  }
  open_updateVerification() {
    this.googleAuthNumber = ""
    this.password = ""
    $('#updateVerification').modal('show')
  }
  googleAuth = false
  googleAuthNumber: any
  password: any
  deleteAddress() {
    // if (this.towFaType == 'NONE') {

      this.addFaqForm.patchValue({
        withdrawAddress: 'NA'
      })
    // }
    // else {
    //   this.googleAuth = true
    // }
  }
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }
  deleteAddress2() {
    this.addFaqForm.patchValue({
      withdrawAddress: 'NA'
    })
  }
  loginSessionData: any;
  openTransferModal() {
    $('#transferAmount').modal('show')
  }
  isLoading = false
  planId : string
  getlist() {

    let url = `vesting/getSaleList?page=${1 }&pageSize=${1}`;

    // this.service.showSpinner();
    
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        
        let temp = res.data.docs.find((ele)=>{
          return ele.icoStatus == 'ENABLE'
        })
        if(temp){
          this.planId = temp.planId
          this.connectToMetaMask(this.userData.internalWallet, this.userData.withdrawAddress, temp.contractAddress)

        }
        else{
          this.service.toasterErr("No plan is currently enabled.")
        }
   
       
    
      } else {
     
        this.service.hideSpinner();
      }
    },
      (err: any) => {
        
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }
  async connectToMetaMask(inbuiltAddress,walletAddress, contractAddress) {
    if (window.ethereum) {
      this.isLoading = true
      try {
        const chainId = 1001
        const web3 = new Web3(window.ethereum);
        if (window.ethereum.networkVersion !== chainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chainId) }]
            });
            this.doTrnasaction(inbuiltAddress,walletAddress,contractAddress)
          } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
              
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'FIERO',
                    chainId: web3.utils.toHex(chainId),
                    nativeCurrency: { name: 'FIERO', decimals: 18, symbol: 'FIERO' },
                    rpcUrls: ['https://fieroscan.com/']
                  }
                ]
              });
              this.doTrnasaction(inbuiltAddress,walletAddress,contractAddress)
            }
            else{
              this.service.toasterErr(err.message)

              console.log(err);
              this.isLoading = false
            }
          }
        }
      } catch (error) {
        this.service.toasterErr(error.message)

        console.log(error);
        this.isLoading = false
        return
      }
     
      
    } else {
      this.isLoading = false
      console.error('MetaMask not found');
      this.service.toasterErr('MetaMask not found')
    }
  }

  async doTrnasaction(inbuiltAddress,walletAddress, contractAddress){
    try {
      // Request account access
      let isConnected = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('MetaMask connected', isConnected);
      debugger
      const web3 = new Web3(window.ethereum);
      const constant = new contractHelper()
      const newVestingContract = await new web3.eth.Contract(constant.nodeContractDeployAbi, contractAddress);
      const txObject = {
        from: isConnected[0],
        to: contractAddress,
        gasPrice: Web3.utils.toHex('2000000000'),    // Always in Wei
        gasLimit: Web3.utils.toHex('800000'),
        value: Web3.utils.toWei(this.loylity.value.loyalityAmount.toString(), "ether"),
      };
      // const txHash = await window.ethereum.request({ method: 'eth_sendTransaction', params: txObject });
      const txHash = await newVestingContract.methods.allocateForVesting(
        inbuiltAddress, walletAddress
      ).send(txObject)
      console.log('Transaction sent:', txHash.transactionHash);
      this.transferLoyltyMoney(txHash.transactionHash)
    } catch (error) {
      this.isLoading = false
      console.error(error);
      this.service.toasterErr(error.message)
    }
  }

  transferLoyltyMoney(transactionHash) {
    this.service.showSpinner()
    let url = `wallet/transaferReward`
    const data = {
      userId : this.userId,
      amount : this.loylity.value.loyalityAmount,
      transactionHash : transactionHash,
      planId : this.planId
    }
    this.service.post(url, data).subscribe(
      (res: any) => {
        if (res.status == 200) {

          $('#transferAmount').modal('hide')
          this.isLoading = false
          this.service.toasterSucc(res['message'])
          this.service.hideSpinner();
        } else {
          $('#transferAmount').modal('hide')
          this.service.toasterSucc(res['message'])
          this.service.hideSpinner();
        }
      },
      (err) => {
        $('#transferAmount').modal('hide')
        this.service.hideSpinner();
        this.service.toasterSucc(err['message'])
        if (err.status == 401) {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something went wrong");
        }
      }
    );
  }
  stateListFetch(countryCode) {
    this.service.showSpinner()
    let url = `static/stateList?countryCode=${countryCode}`
 
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {

         this.stateList = res.data
         this.service.hideSpinner();
        } else {
         
          this.service.toasterSucc(res['message'])
          this.service.hideSpinner();
        }
      },
      (err) => {
       
        this.service.hideSpinner();
        this.service.toasterSucc(err.error['message'])
        
      }
    );
  }
  getStateList(e){
    let find = this.countryList.find((ele)=>{
      return ele.name == e
    })
    console.log(find);
    this.stateListFetch(find.code)
    
  }
}


let obj = new countryJson()