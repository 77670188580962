import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-news-letter-view',
  templateUrl: './news-letter-view.component.html',
  styleUrls: ['./news-letter-view.component.css']
})
export class NewsLetterViewComponent implements OnInit {

  addForm: FormGroup
  advertisementList: any;

  imgSrc: any = []
  productImageDto: any = []
 
  image: string;
  imageuploaded: boolean = true;
  newsId : any
  newsDetail : any 
  description : any
  constructor(private router: Router, public mainService: MainService,private activate : ActivatedRoute,public sanitizer: DomSanitizer) { 
    this.activate.queryParams.subscribe((res)=>{
      this.newsId  = res.newsId
    })
  }

  ngOnInit() {
    this.getNews()
   
  }

  getNews() {
    let url = `account/get-news-letter-by-id?newsLetterId=${this.newsId}`
    
   
    this.mainService.showSpinner();
    this.mainService.get(url).subscribe((res) => {
      if (res["status"] == 200) {

        this.newsDetail = res['data']
        this.description = res['data']['description']
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["responseMessage"]);
       
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["responseMessage"]);
      }
    });
  }

 
  back() {
    this.router.navigate(['/advertisement-list'])
  }
  editAdvertisement() {
   
    this.router.navigate(['/news-letter-edit'], { queryParams: { newsId: this.newsId } })
  }

}
