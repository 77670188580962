import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';


@Component({
  selector: 'app-fieres-tutorial-add',
  templateUrl: './fieres-tutorial-add.component.html',
  styleUrls: ['./fieres-tutorial-add.component.css']
})
export class FieresTutorialAddComponent implements OnInit {
  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  editImage: any
  languageArrs = languagesArray
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute, protected _sanitizer: DomSanitizer) {
    this.active.queryParams.subscribe((params) => {
      this.currTab = params.tab
      console.log('jjf', this.currTab);

    })
  }

  ngOnInit(): void {
    this.formValidation();
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^(?!.*\b[^\s]{10,}\b).*$/)]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl(''),
      'url': new FormControl('',[Validators.pattern(/^https:\/\/[a-zA-Z0-9\-._~:\/?#@!$&'()*+,;=%]+$/)]),
      'videoUrl': new FormControl('', [Validators.required,Validators.pattern(/^https:\/\/.*\.(mp4|avi|mkv|mov|flv|wmv|webm|ogg)$/)]),
      'language': new FormControl('', [Validators.required])
    })
  }
  // add faq language

  // add faq english
  addFaq() {
    let url = `static/addAcademy`
    let request = {
      "description": this.addFaqForm.value.description,
      "imageUrl": this.editImage,
      "link": this.addFaqForm.value.url,
      "title": this.addFaqForm.value.title,
      "videoUrl": this.addFaqForm.value.videoUrl,
      "language": this.addFaqForm.value.language,
    }
    console.log('f', 'jd');

    let data = this.service.bodyToData(request)

    this.service.post(url, data).subscribe((res: any) => {
      if (res.status = 200) {
        console.log('jjh', res);

        this.service.toasterSucc("Tutorial added successfully.")
        this.route.navigate(['/fieres-tutorial-list'])
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })

  }

  // Image Functionality Start Here
  uploadImg($event): void {
    this.editImage = $event.target.files[0];
    // this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.editImage = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  // videoValue:any
  // videoUrl(e){
  //   console.log(e);
  //   let value  =e
  //   this.videoValue = this._sanitizer.bypassSecurityTrustResourceUrl(value);
  // }
}
const languagesArray = [
  {"code":"fr","name":"French","nativeName":"français, langue française"},
  {"code":"it","name":"Italian","nativeName":"Italiano"},
  {"code":"en","name":"English","nativeName":"English"},
  {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"}
]