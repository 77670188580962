<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:#fff">Edit ICO Sales


            </h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30  mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class=" input-style">
                        <form [formGroup]="addFaqForm">
                            <div class="form-group row ">

                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox">Enter ICO Name:

                                    </label>
                                    <input type="text" class="form-control " formControlName="plan"
                                        (keypress)="service.preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('plan')?.hasError('required') && (addFaqForm.get('plan').dirty || addFaqForm.get('plan').touched )"
                                            class="error" padding>*Plan name is required</p>
                                    </span>
                                    <!-- <span class="error">
                                      <p *ngIf="addFaqForm.get('title')?.hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                          class="error" padding>*Please enter valid question</p>
                                  </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <label class="col-md-4" class="labelBox"> Enter Price:



                                    </label>
                                    <input type="text" class="form-control " formControlName="price"
                                        (keypress)="service.preventSpace($event);service.numberOnly($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('price')?.hasError('required') && (addFaqForm.get('price').dirty || addFaqForm.get('price').touched )"
                                            class="error" padding>*Price is required</p>
                                    </span>
                                    <!-- <span class="error">
                                      <p *ngIf="addFaqForm.get('title')?.hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                          class="error" padding>*Please enter valid question</p>
                                  </span> -->
                                </div>
                                <div class="col-md-6 col-sm-12" style="margin-top: 20px;">
                                    <label class="col-md-4" class="labelBox">Enter Token Quantity:





                                    </label>
                                    <input type="text" class="form-control" formControlName="tokenQuantity"
                                        (keypress)="service.preventSpace($event); service.numberOnly($event)" />

                                    <span class="error">
                                        <p *ngIf="addFaqForm.get('tokenQuantity')?.hasError('required') && (addFaqForm.get('tokenQuantity').dirty || addFaqForm.get('tokenQuantity').touched )"
                                            class="error" padding>*Quantity is required</p>
                                    </span>
                                    <!-- <span class="error">
                                      <p *ngIf="addFaqForm.get('title')?.hasError('pattern') && (addFaqForm.get('title').dirty || addFaqForm.get('title').touched )"
                                          class="error" padding>*Please enter valid question</p>
                                  </span> -->
                                </div>


                            </div>


                            <div class="text-center mt40" style="margin-right: -14%;">
                                <button class="btn btn-large  max-WT-200 font-100 btn-green mr-5" (click)="publishICO()"
                                    [disabled]="!addFaqForm.valid">Update</button>
                                <a class="btn btn-large  max-WT-200 font-100 btn-grey ml8"
                                    [routerLink]="['/icoSalesListing']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>