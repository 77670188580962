import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'two-fa-modal',
  templateUrl: './two-fa-modal.component.html',
  styleUrls: ['./two-fa-modal.component.css']
})

export class TwoFaModalComponent implements OnInit {
  @Input() parameter : any
  @Input() userDetail : any

  @Output() onVerify = new EventEmitter;
  @Output() close = new EventEmitter;
  twoFAStatus: string;
  googleAuth: any;
  secretKey: any;
  ipAddress: any;
  qrCode: any;

  constructor(public service:MainService) { }

  ngOnInit(): void {
    if(this.userDetail.twoFaType == 'GOOGLE'){
      $('#googleAuth').modal({ backdrop: 'static' });
    }
    else{
      this.enableGoogleAuth()
    }
    
  }
  verifyGoogleAuth() {
    var apireq = {};
    var url = '';
    
      url = 'user/verifyGoogleAuth';
      apireq = {
        "otp": this.googleAuth,
        //"secretKey": this.secretKey
      }
    
    this.service.showSpinner();
    this.service.postApi(url, apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        
        if (this.twoFAStatus == 'GOOGLE') {
          this.service.changeLoginSub('login');
          // localStorage.setItem('Auth', res['data']);
          sessionStorage.setItem('secretKey', this.secretKey);
        }
        this.service.toasterSucc('Google Authentication Verified');
        $('#googleAuth').modal('hide');
        this.removeToken()
        this.onVerify.emit(this.parameter)
        // this.router.navigate(['']);
        
      }
      else {
        this.service.toasterErr('Wrong Google Authentication Code');
        this.googleAuth = ''
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
}
 // Enable Google Auth Functionality
  enableGoogleAuth() {
    if (!this.userDetail.email) {
      return this.service.toasterErr('Please update your profile.')
    } else {
      if (
        this.userDetail.twoFAType == "SKIP" ||
        this.userDetail.twoFAType == "NONE"
      ) {
        this.service.showSpinner();
        this.service.get("user/googleAuth").subscribe(
          (res) => {
            if (res["status"] == 200) {
              this.qrCode = res["data"]["qrCode"];
              this.secretKey = res["data"]["secretKey"];
              $("#googleAuth").modal({ backdrop: "static", keyboard: false });
              this.service.hideSpinner();
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
      } 
      if(this.userDetail.twoFAType == "GOOGLE"){
        $("#googleAuth").modal({ backdrop: "static", keyboard: false });
      }
    }
  }

restrictSpace(event) {
  var k = event.charCode;
  if (k === 32) return false;
}
removeToken() {
  this.close.emit()
}
/** Function to restrict character */
restrictChar(event) {
  var k = event.charCode;
  if (event.key === 'Backspace')
    k = 8;
  if (k >= 48 && k <= 57 || k == 8 || k == 46)
    return true;
  else
    return false;
}
copyToClipboard(item) {
  this.service.toasterSucc('Secret key copied')
  document.addEventListener('copy', (e: ClipboardEvent) => {
    e.clipboardData.setData('text/plain', (item));
    e.preventDefault();
    document.removeEventListener('copy', null);
  });
  document.execCommand('copy');
}
  suggesstionFunc(){
    if (this.twoFAStatus == 'GOOGLE') {
      $('#googleAuth').modal('show');
    } else {
      this.service.showSpinner();
      this.service.get('user/googleAuth').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.qrCode = res['data']['qrCode'];
          this.secretKey = res['data']['secretKey'];
          $('#googleAuth').modal('show');
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
}

}
