<main class="middle-content">
    <div class="page_title_block d-flex justify-content-between  align-items-center">
        <h1 class="page_title" style="color:#fff">Sale Trances Management</h1>
        <div class="d-flex justify-content-between align-items-center ">
            <h1 class="page_title" style="color:#fff">Fiero price: {{fieroPrice || 0}} USD</h1>
            <button type="submit" class="btn  btn-theme" (click)="goToAddIco()" style="    margin-left: 25px;
            ">Update ICO
            </button>
        </div>


    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="userForm">
                                <div class="row form-group">
                                    <div class="col-lg-3 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>
                                                <input type="date" onkeydown="return false" class="form-control form"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-6">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="date" class="form-control form" onkeydown="return false"
                                                    [readonly]="!this.userForm.controls['startdate'].value"
                                                    [min]="userForm.controls['startdate'].value"
                                                    [max]="today"
                                                    formControlName="enddate">
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="col-lg-3 col-md-4 col-sm-6" style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group w-20">
                                                <input type="datetime" class="form-control w-75" placeholder="ICO name"
                                                    formControlName="searchText">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 " style="margin-top:26px;">
                                        <div class="serch-boxuser">
                                            <div class="input-group filter_search_group">
                                                <select formControlName="userStatus" class="form-control form1">
                                                    <option value="">Select Status</option>
                                                    <option value="ENABLE">ENABLE</option>
                                                    <option value="DISABLE">DISABLE</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-md-12 justify-content-end">
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="text-left" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme cus-mr"
                                                            (click)="pageNumber = 1;getlist()">Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 1%">
                                                        <button type="submit" class="btn  btn-theme"
                                                            (click)="reset()">Clear
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="margin-top:26px;"
                                            *ngIf="service.isShow(permissionArr,'TOKEN_TRANS_MANAGEMENT','UPDATE')">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 7%">
                                                        <button type="submit" class="btn btn-theme"
                                                            (click)="exportAsXLSX()"> EXPORT AS EXCEL </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class=" "
                                            *ngIf="service.isShow(permissionArr,'TOKEN_TRANS_MANAGEMENT','CREATE')"
                                            style="margin-top:26px;">
                                            <div class="serch-boxuser">
                                                <div class="input-group filter_search_group">
                                                    <div class="head_flt_select input-label" style="margin-left: 12%">
                                                        <button type="submit" class="btn  btn-theme"
                                                            (click)="goToAddIco()">Update ICO
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>

                            </form>
                        </div>
                        <!-- <div class="user-main-head sec_head_new sec_head_new_after usd">
                           <app-usd-price></app-usd-price>
                        </div> -->
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">ICO Name</th>
                                        <th scope="col">Price (USD)
                                        </th>
                                        <th scope="col">Token Quantity
                                        </th>
                                        <!-- <th scope="col">Vesting Rules
                                        </th> -->
                                        <!-- <th scope="col">Sale Start Date</th>
                                        <th scope="col">Sale End Date</th> -->
                                        <!-- <th scope="col">Description
                                        </th> -->
                                        <th scope="col">Status
                                        </th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="listing?.length">
                                    <tr
                                        *ngFor="let item of listing | paginate: { itemsPerPage: itemsPerPage, currentPage: pageNumber, totalItems: count };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td class="name">{{item?.planName}}</td>
                                        <td>{{item?.fieroUsdPrice || 'N/A'}}</td>

                                        <td>{{item?.totalQuantity || 'N/A'}}</td>
                                        <!-- <td>{{item?.vestingRules || 'N/A'}}</td> -->
                                        <!-- <td>{{(item?.saleDate | date:'medium') || 'N/A'}}</td>
                                        <td>{{(item?.saleUptodate | date:'medium') || 'N/A'}}</td> -->

                                        <!-- <td>{{item?.enterDesc || 'N/A'}}</td> -->
                                        <td>{{item?.icoStatus == 'ENABLE' ? 'Enabled' : 'Disabled' || 'N/A'}}</td>
                                        <td>{{(item?.saleDate | date : 'dd-MM-yyyy') || 'N/A'}}</td>
                                        <td>{{item?.saleUptodate | date : 'dd-MM-yyyy'}}</td>
                                        <td class="action_td_btn3">
                                            <a [routerLink]="['/viewICO']" [queryParams]="{id : item?.icoId}"
                                                *ngIf="service.isShow(permissionArr,'TOKEN_TRANS_MANAGEMENT','READ')"><i
                                                    class="fa fa-eye" aria-hidden="true"></i></a>
                                            <!-- <a [routerLink]="['/editICO']" [queryParams]="{id : item?.icoId}"
                                                *ngIf="service.isShow(permissionArr,'TOKEN_TRANS_MANAGEMENT','UPDATE')"><i
                                                    class="fa fa-edit" aria-hidden="true"></i></a> -->
                                            <!-- <ng-container
                                                *ngIf="service.isShow(permissionArr,'TOKEN_TRANS_MANAGEMENT','UPDATE')">
                                                <a (click)="block('ENABLE',item?.icoId)"
                                                    *ngIf="item?.icoStatus=='DISABLE'" data-toggle="modal"><i
                                                        class="fa fa-ban" style="color: #ee6b7b;"
                                                        aria-hidden="true"></i></a>
                                                <a (click)="block('DISABLE',item?.icoId)"
                                                    *ngIf="item?.icoStatus=='ENABLE'" data-toggle="modal"><i
                                                        class="fa fa-ban" style="color: green;"
                                                        aria-hidden="true"></i></a>
                                            </ng-container> -->

                                            <!-- <a (click)="delete(item.icoId)"  *ngIf="service.isShow(permissionArr,'TOKEN_TRANS_MANAGEMENT','DELETE')"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></a> -->
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!listing?.length">
                                    <tr>
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5>No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="count > 9">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete</h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 *ngIf="userStatus=='ENABLE'" style="text-align: center; ">ENABLE </h3>
                        <h3 *ngIf="userStatus=='DISABLE'" style="text-align: center; color: #fff;">DISABLE </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="userStatus=='ACTIVE'" style="font-size: 25px;">Are you sure you want to
                                    ENABLE?</p>
                                <p *ngIf="userStatus=='BLOCK'" style="font-size: 25px;  color: #fff;">Are you sure you
                                    want to DISABLE?
                                </p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>