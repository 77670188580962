import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { ApiUrls } from 'src/app/config/api-urls'
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  activeUserLength: any;
  blockedUserLength: any;
  userListLength: any;
  pendingUserLength: any;
  kycpendingListlength: any;
  TotalUserCount: any;
  totalDepositeCount: any;
  totalCoinCount: any;
  totaltokenCount: any;
  userList: any;
  permissionArr: any

  constructor(private router: Router, public service: MainService) { 
    if(!localStorage.getItem('Auth')){
      // router.navigate(['login'])
    }
  }

  ngOnInit() {
    this.getDashboardData()


    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  getDashboardData() {
    var url = ApiUrls.dashboard;
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data'][0]
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr(err.error.message);
      }
    })
  }
  // Get List of User
  getUserList() {
    var url = "account/admin/dashboard/getUserCountByStatus";
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        var userList = res['data'];
        console.log('ffdhdj', userList);
        this.activeUserLength = userList.activeUsersCount;
        this.blockedUserLength = userList.blockedUsersCount;
        this.pendingUserLength = userList.pendingUserCount;
        this.userListLength = this.activeUserLength + this.blockedUserLength + this.pendingUserLength;
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr(err.error.message);
      }
    })
  }

  // getListOFKYC Function
  getListOfKyc() {
    var url = 'account/admin/dashboard/pendingKycCount';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.kycpendingListlength = res['data'];
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr(err.error.message);
      }
    })
  }


  // getListOfTotalUserCount Function
  getListOfTotalUserCount() {
    var url = 'account/admin/dashboard/totalUserCount';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.TotalUserCount = res['data'];
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        // this.service.toasterErr(err.error.message);
      }
    })
  }

  // get-deposit-and-coin-count Function
  getDepositCoinCount() {
    var url = 'wallet/get-deposit-and-coin-count';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.totalDepositeCount = res['data'].depositCount;
        this.totalCoinCount = res['data'].coinCount;
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }

  // total token register Function
  tokenRegister() {
    var url = 'wallet/admin/get-token-count';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.totaltokenCount = res['data'].coinCount;

      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }

  listBuyToken: any
  // get list of buy token
  // getListOFKYC Function
  // getListOfBuyToken() {
  //   var url = 'wallet/wallet/get-toga-balance-remaining';
  //   this.service.showSpinner();
  //   this.service.get(url).subscribe(res => {
  //     this.service.hideSpinner();
  //     if (res['status'] == 200) {
  //       this.listBuyToken = res['data'][0];
  //     } else {
  //       this.service.hideSpinner()
  //       this.service.toasterErr(res['message']);
  //     }
  //   }, err => {
  //     this.service.hideSpinner();
  //     if (err['status'] == '401') {
  //       this.service.onLogout();
  //       this.service.toasterErr('Unauthorized Access');
  //     } else {
  //       // this.service.toasterErr(err.error.message);
  //     }
  //   })
  // }
  tokenSold: any

  isShow(managementName) : boolean {
    if(localStorage.getItem('usertype') == 'ADMIN'){
      return true
    }
    if(Object.keys(this.permissionArr).length){
      if(this.permissionArr[managementName].length){
        return true
      }
      else{
        return false
      }
      
    }
    return true
  }
}
