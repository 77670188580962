import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-news-letter-edit',
  templateUrl: './news-letter-edit.component.html',
  styleUrls: ['./news-letter-edit.component.css']
})
export class NewsLetterEditComponent implements OnInit {
  addForm: FormGroup
  advertisementList: any;
  

  
 
  imgSrc: any = []
  productImageDto: any = []
 
  image: string;
  imageuploaded: boolean = true;
  newsId : any
  
  constructor(private router: Router, public mainService: MainService,private activated : ActivatedRoute) { 
    this.activated.queryParams.subscribe((res)=>{
      this.newsId  = res.newsId
    })
  }

  ngOnInit() {

    this.addBannerFormValidation();
    this.getNews()
  }

  //----- validation -------//
  addBannerFormValidation() {
    this.addForm = new FormGroup({
      title: new FormControl("", [Validators.required]),
      url: new FormControl("", [Validators.required,Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
      description: new FormControl("", [Validators.required]),
      image: new FormControl(""),
    
      // postedDate: new FormControl(new Date(), [Validators.required]),
      
    })

  }

  //------ add google map for location with latitude and longitude-----//


  getNews() {
    let url = `account/get-news-letter-by-id?newsLetterId=${this.newsId}`
    
   
    this.mainService.showSpinner();
    this.mainService.get(url).subscribe((res) => {
      if (res["status"] == 200) {

        this.addForm.patchValue({
          title: res['data']['title'],
          url : res['data']['url'],
          description: res['data']['description'],
        })
        this.imgSrc[0] = res["data"]["imageUrl"]
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
       
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }




 
  //-------- add  advertisement --------//
  addAdvertisement() {
    if(this.imgSrc[0] == 'assets/img/loaderadvertisement.gif'){
      this.mainService.toasterInfo("Please wait while image is uploading")
      return
    }
    let url = `account/update-news-letter?newsLetterId=${this.newsId}`
    const data = {
      "description": this.addForm.value.description,
      "imageUrl": this.imgSrc[0],
      'url' : this.addForm.value.url,
      "title": this.addForm.value.title
    }
    console.log(data)
    this.mainService.showSpinner();
    this.mainService.postApi(url, data).subscribe((res) => {
      if (res["status"] == 200) {
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["message"]);
        this.router.navigate(['/news-letter-list'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["message"]);
      }
    });
  }

  //--------- upload image functionality-------//
  //--------- upload image functionality-------//
  uploadImg(event): void {
    this.imageuploaded = true;
    if (event.target.files && event.target.files[0]) {
      const max_height = 90;
      const max_width = 1140;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          this.uploadProfilePic(event.target.files[0]);
          // if (img_height == max_height && img_width == max_width) {
          //   this.uploadProfilePic(event.target.files[0]);
          // } else {
          //   this.image = ''
          //   this.mainService.toasterErr('Please upload image of size 90 or 1140 only.')
          // }
        };
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  uploadProfilePic(img) {
    var form = new FormData()
    form.append('file', img)
    this.imgSrc[0]="assets/img/loaderadvertisement.gif"
    let apiReqUrl = "account/upload-file"
    this.mainService.postApi(apiReqUrl, form).subscribe((res: any) => {
      this.mainService.showSpinner();
      if (res["status"] == 200) {
        this.imgSrc[0] = res["data"]
        this.mainService.hideSpinner();
        this.mainService.toasterSucc(res["responseMessage"]);
      }
      else {
        this.mainService.hideSpinner();
        this.mainService.toasterErr(res["responseMessage"]);
      }
    })
  }

  back() {
    this.router.navigate(['/advertisement-list'])
  }

}
