import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
import { contractHelper } from 'src/assets/js/contractHelper';


declare var Web3
// import web3 from 'web3/lib/commonjs/web3.js';

// const Web3 = new web3(new web3.providers.HttpProvider("https://rpc2.fieroscan.com/"))
declare var $
declare let window: any;
@Component({
  selector: 'app-transaction-view-management',
  templateUrl: './transaction-view-management.component.html',
  styleUrls: ['./transaction-view-management.component.css']
})
export class TransactionViewManagementComponent implements OnInit {

  modalForm: FormGroup;
  userId: any;
  viewData: any;
  documentId: any;
  kycId: any;
  kycStatus: any;
  ima: any;
  docData: any;
  fileExten: any;
  txnType: any
  temp = "http://www.africau.edu/images/default/sample.pdf";
  constructor(
    public router: Router,
    public service: MainService,
    public active: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {
    this.active.queryParams.subscribe((params) => {

      this.userId = params.id;
      this.txnType = params.txnType;
    });
  }

  ngOnInit(): void {
    this.viewKyc();

    this.modalForm = new FormGroup({
      reason: new FormControl("", Validators.required),
    });
  }
  userDetail: any;
  showUserDetail(id) {
    this.service.showSpinner();
    var url =
      "account/admin/user-management/user-details?userId=" + id;
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userDetail = res.data;
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  allocateFunds() {
    this.connectToMetaMask(this.viewData.inbuiltAddress , this.viewData.userId.withdrawAddress)
  }

  isLoading = false
  async connectToMetaMask(inbuiltAddress,walletAddress) {
    if (window.ethereum) {
      this.isLoading = true
      try {
        const chainId = 1001
        const web3 = new Web3(window.ethereum);
        if (window.ethereum.networkVersion !== chainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chainId) }]
            });
            this.doTrnasaction(inbuiltAddress,walletAddress)
          } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
              
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'FIERO',
                    chainId: web3.utils.toHex(chainId),
                    nativeCurrency: { name: 'FIERO', decimals: 18, symbol: 'FIERO' },
                    rpcUrls: ['https://fieroscan.com/']
                  }
                ]
              });
              this.doTrnasaction(inbuiltAddress,walletAddress)
            }
            else{
              this.service.toasterErr(err.message)

              console.log(err);
              this.isLoading = false
            }
          }
        }
      } catch (error) {
        this.service.toasterErr(error.message)

        console.log(error);
        this.isLoading = false
        return
      }
     
      
    } else {
      this.isLoading = false
      console.error('MetaMask not found');
      this.service.toasterErr('MetaMask not found')
    }
  }

  async doTrnasaction(inbuiltAddress,walletAddress){
    try {
      // Request account access
      let isConnected = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('MetaMask connected', isConnected);
      debugger
      const web3 = new Web3(window.ethereum);
      const constant = new contractHelper()
      const newVestingContract = await new web3.eth.Contract(constant.nodeContractDeployAbi, this.viewData.planId.contractAddress);
      const txObject = {
        from: isConnected[0],
        to: this.viewData.planId.contractAddress,
        gasPrice: Web3.utils.toHex('2000000000'),    // Always in Wei
        gasLimit: Web3.utils.toHex('800000'),
        value: Web3.utils.toWei(this.viewData.buyAmount.toString(), "ether"),
      };
      // const txHash = await window.ethereum.request({ method: 'eth_sendTransaction', params: txObject });
      const txHash = await newVestingContract.methods.allocateForVesting(
        inbuiltAddress, walletAddress
      ).send(txObject)
      console.log('Transaction sent:', txHash.transactionHash);
      this.updateTransaction(txHash.transactionHash)
    } catch (error) {
      this.isLoading = false
      console.error(error);
      this.service.toasterErr(error.message)
    }
  }
  // Api of view kyc
  updateTransaction(txnHash) {
    var url =
      "wallet/allocateFunds";

    const data = {
      _id: this.userId,
      transactionHash: txnHash
    }
    this.service.post(url, data).subscribe(
      (res: any) => {
        console.log("view", res);
        this.isLoading = false
        if (res.status == 200) {
          this.viewKyc()
          //  this.showUserDetail(res.data.fkUserId)

          if (this.txnType != 'REWARDED') {
            // this.approveKyc(res.data.paymentId)
          }

        }
      },
      (err) => {
        this.isLoading = false
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access.");
        } else {
          this.service.toasterErr("Something went wrong.");
        }
      }
    );
  }

  show(): boolean {

    if (this.viewData.transactionType == 'BUY_PLAN') {
      if (this.viewData.transactionStatus == 'COMPLETED') {
        if (!this.viewData.transactionHash) {
          return true
        } else {
          return false
        }
      }
      else {
        return false
      }
    } else {
      return false
    }

  }

  // Api of view kyc
  viewKyc() {
    var url =
      "wallet/viewTransactions?_id=" + this.userId;
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("view", res);
        if (res.status == 200) {
          this.viewData = res.data
          //  this.showUserDetail(res.data.fkUserId)

          if (this.txnType != 'REWARDED') {
            // this.approveKyc(res.data.paymentId)
          }

        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access.");
        } else {
          this.service.toasterErr("Something went wrong.");
        }
      }
    );
  }

  approved() {
    if (this.kycStatus == "ACCEPTED") {
      this.service.toasterInfo("Already kyc approved.");
    } else {
      $("#approveModal").modal("show");
    }
  }

  rejected() {
    if (this.kycStatus == "REJECTED") {
      this.service.toasterInfo("Already kyc rejected.");
    } else {
      $("#rejectModal").modal("show");
    }
  }
  updatedTime: any

  paymentDetail: any

  rejectKyc() {
    this.service.showSpinner();
    let data = {
      documentId: this.documentId,
      kycId: this.kycId,
      reason: this.modalForm.value.reason,
      status: "REJECTED",
    };
    this.service
      .post("account/admin/kyc-management/doc-status", data)
      .subscribe(
        (res: any) => {
          console.log("hjuygidsu67tdsuk", res);
          if (res.status == 200) {
            console.log("jkdhsgdfgvs87dfdksjfhg", res);
            this.service.hideSpinner();
            // this.service.toasterSucc(res.message)
            this.service.toasterSucc("KYC rejected successfully.");
            $("#rejectModal").modal("hide");
            this.router.navigate(["/kyc-update"]);
          } else {
            this.service.toasterErr(res.message);
            this.service.hideSpinner();
          }
        },
        (error) => {
          this.service.hideSpinner();
        }
      );
  }

  image() {
    $("#imgg").modal("show");
  }

  backimage() {
    $("#backimgg").modal("show");
  }
  getCountryCode(e) {
    let find = obj.country.find((el) => {
      return el.name == e
    })
    return find.code
  }

}
let obj = new countryJson()