<!-- <body class="afterlogin bg-image"> -->
    <div class="login-wrapper"  style="background-color: #000; position: relative; overflow: hidden;">
        <div class="orangeshadeBox1"></div>
        <div class="purpleBox1"></div>
    <div class="login-wrapper ">
        <div class="container-common">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form class="login_box_outer" [formGroup]="resetPasswordForm">
                        <div class="login-box max-WT-520" style="opacity: 0.8;
                        background: rgba(0,0,1,0.8);">
                            <div class="login-right-block">
                                <div class="text-center">
                                    <img src="assets\img\logo.png" alt="crypto-logo" class="data-img" style="width: auto">
                               
                                </div>
                                <div class="login-heading">
                                    <h4>RESET PASSWORD</h4>
                                </div>
                                <div class="login-box-body">
                                    <div class="form-group eyeicon"> 
                                        <input [type]="showEyeOrCrossEye  ? 'text' : 'password'" class="form-control"
                                            placeholder="Password" formControlName="password" oncopy="return false" onpaste="return false" ondrag="return false" /> 
                                            <em [class]="showEyeOrCrossEye ? 'fa fa-eye':'fa fa-eye-slash'"
                                        aria-hidden="true" (click)="showEyeOrCrossEye = !showEyeOrCrossEye" style="color: gray;"></em>
                                        </div>
                                        
                                    <div
                                        *ngIf="resetPasswordForm.controls['password'].hasError('required') && resetPasswordForm.controls['password'].touched">
                                        <span class="error">*Password is required.</span>
                                    </div>
                                    <div
                                        *ngIf="resetPasswordForm.controls['password'].hasError('pattern') && resetPasswordForm.controls['password'].touched">
                                        <span class="error">*Password must be at least 8 characters, 1 uppercase, 1
                                            lowercase, 1 number and one special character.</span>
                                    </div>
                                    <div class="form-group eyeicon"> <input [type]="showEyeOrCrossEye2  ? 'text' : 'password'" class="form-control"
                                            placeholder="Confirm Password" formControlName="confirmPassword" oncopy="return false" onpaste="return false" ondrag="return false"/> 
                                            <em [class]="showEyeOrCrossEye2 ? 'fa fa-eye':'fa fa-eye-slash'"
                                            aria-hidden="true" (click)="showEyeOrCrossEye2 = !showEyeOrCrossEye2" style="color: gray;"></em></div>
                                    <span
                                        *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required') && resetPasswordForm.controls['confirmPassword'].touched"
                                        class="error">*Confirm password is required.</span> <span class="error"
                                        *ngIf="resetPasswordForm.controls['password'].value != resetPasswordForm.controls['confirmPassword'].value && resetPasswordForm.controls['confirmPassword'].dirty">Password
                                        do not match.</span>
                                    <div class="text-center form-group mt40"> <button type="submit"
                                            class="btn btn-login btn-large width100 font-100"
                                            [disabled]="!resetPasswordForm.valid || (resetPasswordForm.controls['password'].value != resetPasswordForm.controls['confirmPassword'].value)"
                                            (click)="resetPasswordFunc()"
                                            style="width: 53%;">SUBMIT</button> </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>