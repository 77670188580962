<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Community Management</h1>
    </div>

    <!-- tab start -->
    <!-- <div class="custom_tabs common-tabs">
        <div class="row mb20 justify-content-center">
            <div class="col-sm-11" style="padding-left: 0px;padding-right: 0px;">
                <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='English'}" (click)="selectTab('English')"
                            href="javascript:;">English</a>
                    </li>
                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='German'}" (click)="selectTab('German')"
                            href="javascript:;">German</a>
                    </li>
                    <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='Spanish'}" (click)="selectTab('Spanish')"
                            href="javascript:;">Spanish</a>
                    </li>

                </ul>
            </div>
        </div>
    </div> -->
    <!-- tab end -->
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">

                        <!-- <div class="two-btnline mb-4">
                            <div  style="display: flex; justify-content: end"
                                (click)="addFaq(currTab)">
                                <button type="submit" class="btn  btn-theme cus-mr">Add FAQ</button>
                            </div>

                        </div> -->
                        <!-- <div class="row  d-flex justify-content-end w_100">

                           
                            <button type="submit" class="btn  btn-theme" style="margin-right: 1.3%;"
                            routerLink="/community-add">Add Whitepaper
                        </button>
                        </div> -->
                        <!-- tab english faq start -->
                        <div class="tab-pane active show" *ngIf="this.currTab =='English'" style="margin-top: 20px;">
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="user list">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th scope="col">S.No.</th>
                                            <th scope="col">Community Name</th>
                                            <th scope="col">Community Link</th>
                                            <th scope="col">Date & Time <i class="fa fa-sort"
                                                    (click)="sortAgent('updateTime');isAssending=!isAssending"
                                                    aria-hidden="true" style="cursor: pointer;"></i></th>

                                            <th scope="col" class="action_td_btn3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let items of faqData | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:faqData?.length}; let i=index">
                                            <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                            <td class="text-center" style="text-align: center !important;">
                                                {{items?.communityStatus}}
                                            </td>
                                            <td class="faq-ques">
                                                {{items?.imageUrl}}
                                                <!-- <object [data]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file)" type="application/pdf" width="100%" height="100%">
                                                    <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file)" width="100%" height="100%" style="border: none;">
                                                        <p>
                                                            Your browser does not support PDFs.
                                                            <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file)">Download the PDF</a>
                                                            .
                                                        </p>
                                                    </iframe>
                                                </object> -->
                                            </td>
                                            <td>
                                                {{items?.updateTime | date :'medium'}}
                                            </td>
                                            <!-- <td class="faq-ques">
                                                <object [data]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file1)" type="application/pdf" width="100%" height="200px">
                                                    <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file1)" width="100%" height="100%" style="border: none;">
                                                        <p>
                                                            Your browser does not support PDFs.
                                                            <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file1)">Download the PDF</a>
                                                            .
                                                        </p>
                                                    </iframe>
                                                </object>
                                            </td>
                                            <td class="faq-ques">
                                                <object [data]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file2)" type="application/pdf" width="100%" height="200px">
                                                    <iframe [src]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file2)" width="100%" height="100%" style="border: none;">
                                                        <p>
                                                            Your browser does not support PDFs.
                                                            <a [href]="sanitizer?.bypassSecurityTrustResourceUrl(items?.file2)">Download the PDF</a>
                                                            .
                                                        </p>
                                                    </iframe>
                                                </object>
                                            </td> -->



                                            <td class="action_td_btn3">
                                                <!-- <a (click)="viewFaq(items.faqId, currTab)"><i class="fas fa-eye"
                                                        aria-hidden="true"></i></a> -->
                                                <!-- <a (click)="editSkirt(items?.communityId)"><i class="fa fa-edit"
                                                            aria-hidden="true"></i></a> -->
                                                <a (click)="editFaq(items.communityId)"><i class="fa fa-edit"
                                                        aria-hidden="true"></i></a>

                                                <!-- <a (click)="delete(items.communityId)"><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a> -->
                                                <!-- <a (click)="delete(items.faqId, currTab)"><i class="fa fa-trash"
                                                        aria-hidden="true"></i></a> -->
                                                <!-- <a (click)="publish(true,items.faqId,currTab)"
                                                    *ngIf="items?.isPublished==false"><i class="fa fa-ban"
                                                        style="color: green" aria-hidden="true"></i></a>
                                                <a (click)="publish(false,items.faqId, currTab)"
                                                    *ngIf="items?.isPublished==true"><i class="fa fa-ban"
                                                        style="color: red" aria-hidden="true"></i></a> -->
                                            </td>
                                        </tr>
                                        <tr *ngIf="faqData?.length==0">
                                            <td colspan="10" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5>No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- tab english faq end -->
                        <!-- tab german faq start -->

                        <!-- tab german faq end -->
                        <!-- tab spanish faq start -->

                        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="faqData?.length>0">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div> -->
                        <!-- tab spanish faq end -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<!-- delete_modal Start -->
<!-- <div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 *ngIf="this.lan=='English'" style="text-align: center; ">Delete Faq </h3>
                        <h3 *ngIf="this.lan=='German'" style="text-align: center; ">FAQ löschen </h3>
                        <h3 *ngIf="this.lan=='Spanish'" style="text-align: center; ">Eliminar preguntas frecuentes</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="this.lan=='English'" style="font-size: 25px;">Are you sure you want to delete
                                    this Faq?</p>
                                <p *ngIf="this.lan=='German'" style="font-size: 25px;">Möchten Sie diese FAQ wirklich
                                    löschen?</p>
                                <p *ngIf="this.lan=='Spanish'" style="font-size: 25px;">¿Estás segura de que quieres
                                    eliminar este Faq?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="deleteFaqLanguage()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->
<!-- delete_modal End -->

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Community</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this community?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->