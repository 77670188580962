import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-vesting-plan-view',
  templateUrl: './vesting-plan-view.component.html',
  styleUrls: ['./vesting-plan-view.component.css']
})
export class VestingPlanViewComponent implements OnInit {
  planId: number;
  planDetails:any
  constructor(private route: ActivatedRoute, private router: Router, public service: MainService) { 
    this.route.queryParams.subscribe((res)=>{
      this.planId = res.id
    })
  }
 
  ngOnInit(): void {

    this.viewPlan();
  }
  viewPlan() {
    this.service.get(`vesting/viewVestingPlan?id=${this.planId}`).subscribe((res: any) => {
      this.planDetails = res.data
    })
  }

}
