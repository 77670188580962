<!-- Google Auth Modal  -->
<div class="modal fade" id="googleAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal">
      <div class="modal-header">
        <h5 class="modal-title text-white">Google Authentication Verification</h5>
        <button type="button" class="close bck-trns" data-dismiss="modal" aria-label="Close" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="qrCode" class="qr-div mt-4 mb-3 text-center">
          <img [src]="qrCode" *ngIf="qrCode" alt="" />
        </div>
        <p *ngIf="secretKey" style="padding: 0px">
          Secret Key : {{ secretKey }}
          <i class="fa fa-clone" aria-hidden="true" style="cursor: pointer" (click)="copyToClipboard(secretKey)"></i>
        </p>
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input new-input-field-custom-files-all"
            style="text-align: center" (keypress)="restrictSpace($event); restrictChar($event)" maxlength="6"
            placeholder="Enter the google code" [(ngModel)]="googleAuth" />
        </div>
        <div class="btn-modal text-center">
          <button class="btn btn-theme new-button-tab-custom " [disabled]="googleAuth?.length != 6"
            (click)="verifyGoogleAuth()">
            Verify
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth Modal end  -->