<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">ADD ADMINISTRATOR</h1>
        </div>

        <form [formGroup]="addAdmin" autocomplete="off">
            <div class="shadow-none p-3 mb-5 bg-light rounded">
                <div class="user-profile">
                    <div class="image-box">
                        <img [src]="this.imageUrl ||  'assets/img/editprofile.png'" class="profile-pic" alt="profile image">
                        <label class="upload-label">
                            <input type="file" class="hidden" accept=".png, .jpg, .jpeg" (change)="uploadImg($event)" />
                            <i class="fas fa-camera" aria-hidden="true"></i>
                        </label>
                    </div>
                </div>
                <div class="padd_6">
                    <div class="form-group row">
                        <!-- <label class="col-md-3 text" for="">First Name:</label> -->
                        <!-- <div class="col-md-6"></div> -->
                        <div class="col-md-6">
                            <label>First Name</label>
                            <input type="text" class="form-control" placeholder="First Name" formControlName="name" maxlength="30"
                                (keypress)="service.preventSpace($event)" />
                            <span class="error"
                                *ngIf="addAdmin.get('name').hasError('required') && (addAdmin.get('name').dirty || addAdmin.get('name').touched )"
                                class="error">
                                <p>
                                    *Please enter first name.
                                </p>
                            </span>
                            <span class="error"
                                *ngIf="addAdmin.get('name').hasError('pattern') && addAdmin.get('name').dirty"
                                class="error">
                                <p>
                                    *Please enter valid fisrt name.
                                </p>
                            </span>
                        </div>

                        <div class="col-md-6">
                            <label>Last Name</label>
                            <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
                            <span class="error"
                                *ngIf="addAdmin.get('lastName').hasError('required') && (addAdmin.get('lastName').dirty || addAdmin.get('lastName').touched )"
                                class="error">
                                <p>
                                    *Please enter last name.
                                </p>
                            </span>
                            <span class="error"
                                *ngIf="addAdmin.get('lastName').hasError('pattern') && addAdmin.get('lastName').dirty"
                                class="error">
                                <p>
                                    *Please enter valid last name.
                                </p>
                            </span>
                        </div>
                        <div class="col-md-6">
                            <label>Email</label>
                            <input type="email" class="form-control" placeholder="Email" formControlName="email" maxlength="64"
                                (keypress)="service.preventSpace($event)" autocomplete="off"/>
                            <span class="error">
                                <p *ngIf="addAdmin.get('email').hasError('required') && (addAdmin.get('email').dirty || addAdmin.get('email').touched )"
                                    class="error" padding>*Please enter email.</p>
                            </span>
                            <span class="error">
                                <p *ngIf="addAdmin.get('email').hasError('pattern') && addAdmin.get('email').dirty"
                                    class="error" padding>*Please enter valid email.</p>
                            </span>
                        </div>
                        <div class="col-md-6">
                            <label>Mobile Number</label>
                            <input type="text" class="form-control" name="mobile" placeholder="Mobile Number" id="moble" formControlName="contact"  autocomplete="new-password" maxlength="13"> 
                            <span class="error"
                                *ngIf="addAdmin.get('contact').hasError('required') && (addAdmin.get('contact').dirty || addAdmin.get('contact').touched )"
                                class="error">
                                <p>
                                    *Please enter mobile number.
                                </p>
                            </span>
                            <span class="error"
                                *ngIf="addAdmin.get('contact').hasError('pattern') && addAdmin.get('contact').dirty"
                                class="error">
                                <p>
                                    *Please enter valid mobile number.
                                </p>
                            </span>
                        </div>
                        <!-- <div class="col-md-6">
                            <label class="col-md-2 pl-0">Rewarded</label>
                            <label class="col-md-1">:</label>
                            <input class="col-md-1 curs" type="checkbox" formControlName="reward"  name="" id="">
                        </div> -->
                        <!-- <div class="col-md-6">
                            <label>Password:</label>
                            <input type="password" class="form-control" formControlName="password">
                            <div
                                *ngIf="addAdmin.get('password').invalid && (addAdmin.get('password').touched || addAdmin.get('password').dirty)">
                                <span class="error" *ngIf="addAdmin.get('password').hasError('required')">
                                    *Please enter password.</span>
                                <span class="error" *ngIf="addAdmin.get('password').hasError('pattern')">
                                    *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                                    letter, 1
                                    number and 1 special character.</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Confirm Password:</label>
                            <input type="password" class="form-control" formControlName="confirmPassword">
                            <div *ngIf="addAdmin.get('confirmPassword').dirty" class="error">
                                <span class="error"
                                    *ngIf="addAdmin.get('confirmPassword').value != addAdmin.get('password').value">*Password
                                    mismatched.</span>
                            </div>
                        </div> -->

                        <!-- <div class="col-md-6">
                            <label >Select Role:</label>
                            <select class="col-md-9" formControlName="status" class="form-control" >
                                <option value="">Select Role</option>
                                <option value="SUBADMIN">SUBADMIN</option>
                                <option value="ADMIN">ADMIN</option>
                            </select>
                        </div> -->
                    </div>


                </div>
                <span style="color: #fff;    font-size: 26px;">Modules Permission
                </span>
                <div class="row form-group" style="margin-left:25%">


                </div>



                <!--    <div class="col-md-3" style="display: flex; margin-left: 35px; align-items:baseline">
                    <input type="checkbox" (change)="checkboxClick">
                    <label for="" style="white-space: nowrap;margin-left: 10px"></label>





                </div> -->

                <div class="table-responsive">
                    <table class="table table-bordered" aria-describedby="hot wallet list">
                        <thead>
                            <tr class="no_wrap_th">
                                <th scope="col">Managemnets</th>
                                <th scope="col">Create</th>
                                <th scope="col">Update</th>
                                <th scope="col">Read</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of permArray">
                                <ng-container *ngIf="userDeatil === 'ADMIN' || item.keyName !== 'CLAIM_TOKEN_MANAGEMENT'">
                                <td>{{item.keyValue}}</td>
                                <td>
                                    <input type="checkbox" *ngIf="item.access?.includes('CREATE')" class="form-control"
                                        (change)="updatePermission($event.target.checked, item.keyName ,'CREATE' )" name=""
                                        id="">
                                </td>
                                <td>
                                    <input type="checkbox" *ngIf="item.access?.includes('UPDATE')" class="form-control"
                                        (change)="updatePermission($event.target.checked,  item.keyName ,'UPDATE' )" name=""
                                        id="">
                                </td>
                                <td>
                                    <input type="checkbox" *ngIf="item.access?.includes('READ')" class="form-control"
                                        (change)="updatePermission($event.target.checked,  item.keyName ,'READ' )" name=""
                                        id="">
                                </td>
                                <td>
                                    <input type="checkbox" *ngIf="item.access?.includes('DELETE')" class="form-control"
                                        (change)="updatePermission($event.target.checked,  item.keyName ,'DELETE' )" name=""
                                        id="">
                                </td>
                                </ng-container>
                            </tr>
                          
                        </tbody>
                    </table>

                </div>

                <div class="row" style="margin-left: 39%;margin-top: 3%; margin-bottom: 5%;">
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label">
                            <button routerLink="/user-management-admin" type="submit" class="btn  btn-theme" style="background: #333;
                                color: #fff !important;
                                border-radius: 50px !important;
                                font-size: 16px !important;">Cancel</button>
                        </div>
                    </div>
                    <div class="head_flt_select">
                        <div class="head_flt_select input-label" style="margin-left: 10px">
                            <button type="submit" class="btn  btn-theme"
                                [disabled]="!addAdmin.valid"
                                (click)="addSubAdmin()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

</main>
<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; " style="color: #fff">Delete Admin </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;" style="color: #fff">Are you sure you want to delete this
                                    Admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>



<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">

                        <h3 style="text-align: center; color: #fff; ">Block </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;  color: #fff;">Are you sure you want to block?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>


<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">

                        <h3 style="text-align: center; ">Block </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;  color: #fff;">Are you sure you want to block?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>