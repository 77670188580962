<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">VIEW FEE</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <form [formGroup]="viewWithdrawForm">
                <div class="global-table no-radius p0">
                    <div class="shadow p-3 mb-5 bg-white rounded">
                        <div class="row">
                            <div class="col-md-5 col-sm-12">
                                <h5>Withdrawal Limit:</h5>
                            </div>
                            <div class="col-md-2 sm-d-none">
                                <span>:</span>
                            </div>
                            <div class="col-md-5 col-sm-12">
                                <input type="text" class="form-control" formControlName="withdraw">
                            </div>

                        </div>
                        <div class="row justify-content-center mt-3">
                            <button type="submit" class="btn  btn-theme" (click)="updateWithdraw()">save</button>
                            <button type="submit" class="btn  btn-theme ml-3"
                                routerLink="/withdrawl-fee">cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>