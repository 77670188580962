import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-fieres-tutorial-view',
  templateUrl: './fieres-tutorial-view.component.html',
  styleUrls: ['./fieres-tutorial-view.component.css']
})
export class FieresTutorialViewComponent implements OnInit {
  viewFaqForm:FormGroup;
  pageNumber:number=1
  faqId: any;
  editData: any;
  currTab: any;
  constructor(public route:Router,public service:MainService, public active:ActivatedRoute,private sanitizer:DomSanitizer) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.faqId=res.faqId;
      this.currTab=res.lan;
      console.log('fg', this.faqId);
      
    })
  }

  ngOnInit(): void {
      // this.viewFaqForm= new FormGroup({
      //   'title':new FormControl('', [Validators.required]),
      //   'description': new FormControl('', [Validators.required]),
      //   'image': new FormControl('', [Validators.required]),
      //   'url': new FormControl('', [Validators.required])
      // })
 this.editFaq()
  }

// // edit faq language
//   editFaqLanguage(){
//     if(this.currTab=='English'){
//       this.editFaq();
//     }
//     else if(this.currTab=='German'){
//       this.editFaqGerman();
//     }
//     else if(this.currTab=='Spanish'){
//       this.editFaqSpanish();
//     }
//   }
safeSrc:any
  // edit faq english
  editFaq(){
    
   this.service.get("static/viewAcademy?_id="+this.faqId).subscribe((res:any)=>{
     if (res.status=200) {
       console.log('jjh', res);
       this.editData=res.data;
       this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.editData.link);

     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr(err.error.message);
   }
    })

  } 


}
