<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">TAKER MAKER FEE</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="row" style="width: 70%;margin-left: 3%;margin-top: 7%;">
                    <div class="col">
                        <br>
                        <h4 class="btc">BTC</h4>
                        <h4 class="btc">BTC</h4>
                        <h4 class="btc">BTC</h4>
                    </div>
                    <div class="col">
                        <h5 style="margin-left: 13%;">Taker Fee</h5>
                        <input type="text">
                        <input type="text">
                        <input type="text">
                    </div>
                    <div class="col">
                        <h5 style="margin-left: 13%;">Maker Fee</h5>
                        <input type="text">
                        <input type="text">
                        <input type="text">
                    </div>
                </div>
                <button type="submit" class="btn  btn-theme cus-mr" style="width: 39%;margin-left: 28%;margin-top: 5%;"
                    routerLink="/#">Submit</button>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>