<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">Edit Profile</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 ">
                <div class="text-left d-flex justify-content-end">
                    <button type="button" [routerLink]="['/change-password']" class="btn btn-lg btn-success "
                        style="background: transparent;">Change
                        Password</button>
                </div>
                <div class="main-block-innner mb40 ">

                    <div class="add-store-block input-style">
                        <div class="user-profile">
                            <div class="image-box">
                                <img [src]="myImageNew || editImage" class="profile-pic" alt="profile image">
                                <label class="upload-label" style="cursor: pointer">
                                    <input type="file" class="hidden" accept=".png, .jpg, .jpeg"
                                        (change)="uploadImg($event)" />
                                    <i class="fas fa-camera" aria-hidden="true"></i>
                                </label>
                            </div>
                        </div>
                        <form [formGroup]="editForm">

                            <div class="row">
                                <div class="col-md-6">
                                    <label>Name </label>
                                    <input type="text" formControlName="name" placeholder="Enter Name"
                                        class="form-control" maxlength="30" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <!-- && (editForm.get('name').dirty || editForm.get('name').touched ) -->
                                        <p *ngIf="editForm.get('name').hasError('required')  && isSubmitted"
                                            class="error" padding>*Please enter name.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('name').hasError('pattern') && editForm.get('name').dirty"
                                            class="error" padding>*Please enter valid name.</p>
                                    </span>
                                </div>




                                <div class="col-md-6">
                                    <label>Email </label>
                                    <input type="text" disabled placeholder="Enter Email" class="form-control"
                                        maxlength="64" (keypress)="service.preventSpace($event)"
                                        formControlName="email" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('email').hasError('required') && (editForm.get('email').dirty || editForm.get('email').touched )"
                                            class="error" padding>*Please enter email.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('email').hasError('pattern') && editForm.get('email').dirty"
                                            class="error" padding>*Please enter valid email.</p>
                                    </span>

                                </div>

                                <div class="col-md-6">
                                    <label>Mobile Number</label>
                                    <input type="tel" formControlName="phone" placeholder="Enter Mobile Number"
                                        class="form-control" maxlength="15" (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <!-- && (editForm.get('phone').dirty || editForm.get('phone').touched ) -->
                                        <p *ngIf="editForm.get('phone').hasError('required')  && isSubmitted"
                                            class="error" padding>*Please enter mobile number.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('phone').hasError('pattern') && editForm.get('phone').dirty"
                                            class="error" padding>*Please enter valid mobile number.</p>
                                    </span>

                                </div>

                                <div class="col-md-6">
                                    <label>Date of Birth </label>
                                    <input type="date" formControlName="DOB" placeholder="DOB" class="form-control"
                                        [max]='this.date' />
                                    <span class="error">
                                        <!-- && (editForm.get('DOB').dirty || editForm.get('DOB').touched ) -->
                                        <p *ngIf="editForm.get('DOB').hasError('required')  && isSubmitted"
                                            class="error" padding>*Please select DOB.</p>
                                    </span>
                                </div>


                                <div class="col-md-12" style="margin-top: 20px;">
                                    <label>Address </label>
                                    <!-- <input type="text" formControlName="address" placeholder="Enter Address"
                                    class="form-control" minlength="50" maxlength="100"
                                    (keypress)="service.preventSpace($event)" /> -->
                                    <textarea formControlName="address" placeholder="Enter Address" rows="5"
                                        class="form-control"
                                        (keypress)="service.preventSpace($event)"></textarea>
                                    <span class="error">
                                        <!-- && (editForm.get('address').dirty || editForm.get('address').touched ) -->
                                        <p *ngIf="editForm.get('address').hasError('required')  && isSubmitted"
                                            class="error" padding>*Please enter address.</p>
                                        <p *ngIf="editForm.get('address').hasError('minlength')  && isSubmitted"
                                            class="error" padding>*Address must be more than 15 character.</p>
                                        <p *ngIf="editForm.get('address').hasError('maxlength')  && isSubmitted"
                                            class="error" padding>*Address can not be more than 250 character.</p>
                                    </span>
                                </div>

                                <div class="col-md-6">
                                    <label>2 FA </label>
                                    <select class="form-control" formControlName="securityFA" (change)="googleStatusCheck()">
                                        <option value="NONE">NONE</option>
                                        <option value="GOOGLE">GOOGLE</option>
                                    </select>

                                </div>
                            </div>








                            <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Gender </label>
                                <div class="col-md-1"> :</div>
                                <div class="col-md-6">
                                    <input type="text" formControlName="gender" placeholder="Enter Gender"
                                        class="form-control" minlength="1" maxlength="7"
                                        (keypress)="service.preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('gender').hasError('required') && (editForm.get('gender').dirty || editForm.get('gender').touched )"
                                            class="error" padding>*Please enter gender.</p>
                                    </span>
                                    <span class="error">
                                        <p *ngIf="editForm.get('gender').hasError('pattern') && (editForm.get('gender').dirty || editForm.get('gender').touched )"
                                            class="error" padding>*Please enter valid gender.</p>
                                    </span>
                                </div>
                            </div> -->

                            <div class="text-left mt40" style="    display: flex;
                            justify-content: center;">
                                <button class="btn btn-large max-WT-200 font-100 btn-green mr-5"
                                    routerLinkActive="router-link-active" [disabled]="editForm?.invalid && isSubmitted"  (click)="updateProfile()"
                                    style="    background: #333;">Update Profile</button>
                                <a href="javascript:void(0)" class="btn btn-large max-WT-200 font-100 btn-green ml8"
                                    [routerLink]="['/my-profile']">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<!-- Google Auth Modal  -->
<div class="modal fade" id="googleAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal bg-black-color">
      <div class="modal-header">
        <h5 class="modal-title text-white">
          Google Authentication Verification
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute;
        right: 10px;
        top: 13px !important;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="qrCode" class="qr-div mt-4 mb-3 text-center">
          <img [src]="qrCode" *ngIf="qrCode" alt="" />
        </div>
        <p *ngIf="secretKey" style="padding: 0px" class="text-center">
          Secret Key : {{ secretKey }}
          <i class="fa fa-clone" aria-hidden="true" style="cursor: pointer" (click)="copyToClipboard(secretKey)"></i>
        </p>
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align: center"
            (keypress)="restrictSpace($event); restrictChar($event)" maxlength="6" placeholder="Enter the google authenticator code"
            [(ngModel)]="googleAuth" />
        </div>
          <div class="btn-modal text-center">
            <button class="btn btn-green mr-2 text-center" [disabled]="googleAuth.length != 6"
              *ngIf="userDetail.twoFaType != 'GOOGLE'" (click)="verifyGoogleAuth()">
              Enable
            </button>
          </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth Modal end  -->

<!-- Google Auth disable Modal  -->
<div class="modal fade" id="googleAuthDisable" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content after-login-modal bg-black-color">
      <div class="modal-header">
        <h5 class="modal-title text-white">Google Authentication</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute;
        right: 10px;
        top: 13px !important;" (click)="removeToken()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group search-div mt-3">
          <input type="address" class="form-control after-input" style="text-align: center"
            (keypress)="restrictSpace($event); restrictChar($event)" maxlength="6" placeholder="Enter the google authenticator code"
            [(ngModel)]="googleAuth" />
        </div>
          <div class="btn-modal text-center">
            <button class="btn btn-green mr-2 text-center" [disabled]="googleAuth.length != 6" (click)="disableTwoFA()">
              Disable
            </button>
          </div>
      </div>
    </div>
  </div>
</div>
<!-- Google Auth disable Modal end  -->
<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>
