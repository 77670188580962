import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-subadmin-activity',
  templateUrl: './subadmin-activity.component.html',
  styleUrls: ['./subadmin-activity.component.css']
})
export class SubadminActivityComponent implements OnInit {

  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;

  totalRecords: any;
  pageNumber: number = 1;
 
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().split('T')[0]
  permissionArr: any = {}
  constructor(
    private router: Router, public service: MainService
  ) {
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getTicketList();
  }
  changeMessage(value) {
    let newValue = value.replaceAll('_', ' ')
    return newValue
  }
  getTicketList() {

    let startdate = this.userForm.value.startdate
    let enddate = this.userForm.value.enddate
    let search = this.userForm.controls.searchText.value;
    this.service.showSpinner();
    const data = {
      page : String(this.pageNumber),
      limit : String(this.limit),
      search : search,
      fromDate : startdate,
      toDate : enddate
    }
    let url = `admin/listSubAdminActivity`
    this.service.post(url, data).subscribe((res: any) => {
      this.service.hideSpinner()
      if(res.status == 200){
      this.ticketList = res.data.docs
      this.totalRecords = res.data.total;
      }else{
      this.ticketList = []
      }
    },(err)=>{
      this.service.hideSpinner()
      this.ticketList = []
    })
    
  }
  filterTicketList() {
    let startdate = Date.parse(this.userForm.value.startdate)
    let enddate = Date.parse(this.userForm.value.enddate)
    let search = this.userForm.controls.searchText.value;
    let status = this.userForm.controls.status.value;
    if (startdate && enddate && search && status) {
      var request = {
        'fromDate': startdate,
        'search': search,
        'ticketStatus': status,
        'toDate': enddate,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }
    }

    else if (startdate && enddate) {
      var request1 = {
        'fromDate': startdate,
        'toDate': enddate,
        'page': this.pageNumber - 1,
        'pageSize': this.limit

      }
    }
    else if (search) {
      var request2 = {
        'search': search,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }

    }

    else if (status) {
      var request3 = {
        'ticketStatus': this.userForm.controls.status.value,
        'page': this.pageNumber - 1,
        'pageSize': this.limit
      }


    }
    console.log('j', request, request1, request2, request3);
    this.service.showSpinner()
    this.service.post(`static/search-and-filter-ticket-list`, request || request1 || request2 || request3).subscribe((res: any) => {
      this.service.hideSpinner()

      if(res.status == 200){
    this.ticketList = res.data.list
    console.log('hh', this.ticketList);

    this.totalRecords = res.data.count;
   }else{
    this.ticketList = []
    }
  },(err)=>{
    this.service.hideSpinner()
    this.ticketList = []
  })
    console.log('hitted');
    console.log(status);
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    this.page = page
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.filterTicketList();
    } else {
      this.service.hideSpinner();
      this.getTicketList();
    }
  }
  updateTicketStatus(e, id: number) {
    let status = e.target.value
    id = id
    let data = {}
    this.service.post(`static/change-ticket-status?ticketId=${id}&ticketStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.toasterSucc(res.message)
      }
    })
  }

  // reset
  // reset(){
  //   this.userForm.reset()
  //   this.getTicketList(this.page)
  // }
  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.status
    ) {
      this.userForm.reset({
        startdate: "",
        // userStatus: "",
        enddate: "",
        searchText: "",
        status: "",
      });
      this.getTicketList();
    }
  }
  replyTicket() {
    this.router.navigate(['/reply-ticket'])
  }
  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }

  //export User
 async exportAsXLSX() {
  let temp = [];
  let startdate = this.userForm.value.startdate
  let enddate = this.userForm.value.enddate
  let search = this.userForm.controls.searchText.value;
  this.service.showSpinner();
  const data = {
    page : String(this.pageNumber),
    limit : String(this.totalRecords),
    search : search,
    fromDate : startdate,
    toDate : enddate
  }
  let url = `admin/listSubAdminActivity`
    // this.service.get(url).subscribe((res: any) => {
    //   this.ticketList = res.data.list
    //   this.totalRecords = res.data.count;
    // })
    // this.service.hideSpinner()
    try {
      let res: any =  await this.service.post(url, data).toPromise();
    this.service.hideSpinner()
    if(res.status == 200){
      temp = res.data.docs
    }
    } catch (error) {
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }
    let dataArr = [];
    temp.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        "Sub Admin Email": element.activityBy ? element.activityBy : '',
        "Management Name": this.changeMessage(element.management) ? this.changeMessage(element.management) : '',
        "Activity": this.changeMessage(element.activityOf) ? this.changeMessage(element.activityOf) : 'N/A',
        "Message": element.email + '' + element.lastName ? element.email + " " + (element.lastName ? element.lastName : '') : '',
        "Date & Time": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Sub Admin Activity Management '+ this.today);
  }
  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

}
