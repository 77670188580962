import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { countryJson } from 'src/assets/country';
import { contractHelper } from 'src/assets/js/contractHelper';
declare var Web3
// import web3 from 'web3/lib/commonjs/web3.js';

// const Web3 = new web3(new web3.providers.HttpProvider("https://rpc2.fieroscan.com/"))
declare var $
declare let window: any;
@Component({
  selector: 'app-update-user-wallet',
  templateUrl: './update-user-wallet.component.html',
  styleUrls: ['./update-user-wallet.component.css']
})
export class UpdateUserWalletComponent implements OnInit {
  @Input() userId: any
  userForm: FormGroup;
  currTab: any = "Withdraw";
  withdrawlData: any = [];
  pageNumber: number = 1;
  itemsPerPage: number = 10;
  depositeData: any = [];
  totalRecords: any
  coin: any;
  coinName: any;
  coinData: any = [];
  url: string;
  userDetail: any
  walletBalance: any = [];
  isSearched: boolean = false;
  id: any;
  documentId: any;
  permissionArr: any = {}
  kycId: any;
  today: any = new Date().toISOString().split('T')[0]
  constructor(private router: Router, public service: MainService, private route: ActivatedRoute, private date: DatePipe) {
    this.route.params.subscribe((params) => {
      this.id = params.id

    })
    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
    this.service.twoFA.subscribe((res: any) => {
      this.twoFAStatus = res;
    });
  }

  ngOnInit(): void {
    // this.withdrawl();

    this.userForm = new FormGroup({
      startdate: new FormControl(""),
      enddate: new FormControl(""),
      searchText: new FormControl(""),
      country: new FormControl(""),
      userStatus: new FormControl(""),
      ramdomId: new FormControl("")
    });
    this.coinList()
    this.myProfile();
    // this.getClaimData();
    // this.countryList();
  }
  products: any = [];
  countryList() {
    let obj = new countryJson()
    this.products = obj.country
  }

  sortType: any = "DESC"
  type: any = "DATE"

  coinList() {

    let startdate = this.userForm.value.startdate;

    let enddate = this.userForm.value.enddate;
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let ramdomId = this.userForm.value.ramdomId;
    let url = `vesting/getUpdateUserWalletList`;

    const data = {
      page: this.pageNumber,
      limit: this.itemsPerPage,
      search: search,
      fromDate: startdate,
      toDate: enddate,
      updateStatus :this.userForm.value.userStatus
    }
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      console.log('df', res);
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinData = res['data']['docs']
        this.totalRecords = res['data']['total']
      } else {
        this.coinData = []

      }

    }, (err) => {
      this.coinData = []
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr(err.error.message);
      }
    })

  }

  // Api of search 
  

  // reset data of search
  reset() {
    // if (
    //   this.userForm.value.startdate ||
    //   this.userForm.value.enddate ||
    //   this.userForm.value.searchText ||
    //   this.userForm.value.ramdomId ||
    //   this.userForm.value.userStatus
    // ) {
    //   this.userForm.reset({
    //     startdate: "",
    //     userStatus: "",
    //     enddate: "",
    //     searchText: "",
    //     country: "",
    //     ramdomId: ""
    //   });
    //   this.coinList();
    // }
    this.pageNumber = 1
       this.userForm.reset({
        startdate: "",
        userStatus: "",
        enddate: "",
        searchText: "",
        country: "",
        ramdomId: ""
      });
    this.coinList();
  }

  //export User
  async exportAsXLSX() {
    let startdate = this.userForm.value.startdate;

    let enddate = this.userForm.value.enddate;
    // let startdate = this.userForm.value.startdate;

    // let enddate = this.userForm.value.enddate;

    var search = this.userForm.value.searchText;
    let ramdomId = this.userForm.value.ramdomId;
    let url = `vesting/getUpdateUserWalletList`;

    const data = {
      page: this.pageNumber,
      limit: this.totalRecords,
      search: search,
      fromDate: startdate,
      toDate: enddate,
      updateStatus :this.userForm.value.userStatus
    }
    this.service.showSpinner();
    try {
      let res: any = await this.service.post(url, data).toPromise();

      this.service.hideSpinner();
      if (res.status == 200) {
        let dataArr = [];
        res.data.docs.forEach((element, ind) => {
          dataArr.push({
            "S no": ind + 1,
            "First Name": element.userId.firstName ? element.userId.firstName : "N/A",
            "Last Name": element.userId.lastName ? element.userId.lastName : "N/A",
            "Email Id": element.userId.email ? element.userId.email : "N/A",
            "Plan Name": element.planId.planName ? element.planId.planName : "N/A",
            "Contract Address": element.planId.contractAddress ? element.planId.contractAddress : "N/A",
            "New Wallet Address": element.newWalletAddress ? element.newWalletAddress : "N/A",
            "Date & Time": element.createdAt ? this.date.transform(element.createdAt, 'medium') : "N/A",
            "Status": element.updateStatus ? element.updateStatus : "N/A",
          });
        });

        this.service.exportAsExcelFile(dataArr, "Updated user wallet list "+ this.today);
      }
    } catch (error) {
      console.log(error)
      this.service.hideSpinner();
      this.service.toasterErr(error.error.message);
    }


  }



  // wallet balance
  // api of Trading


  pagination(page) {
    this.pageNumber = page;
    this.coinList();
  }
  tokenId: any
  tokenIdReject: any
  approved(id) {
    this.tokenId = id
    this.connectToMetaMask(this.viewData.internalWallet, this.viewData.newWalletAddress)
  }
  async connectToMetaMask(inbuiltAddress, walletAddress) {
    if (window.ethereum) {
      this.service.showSpinner()
      try {
        const chainId = 1001
        const web3 = new Web3(window.ethereum);
        if (window.ethereum.networkVersion !== chainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(chainId) }]
            });
            this.doTrnasaction(inbuiltAddress, walletAddress)
          } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {

              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: 'FIERO',
                    chainId: web3.utils.toHex(chainId),
                    nativeCurrency: { name: 'FIERO', decimals: 18, symbol: 'FIERO' },
                    rpcUrls: ['https://fieroscan.com/']
                  }
                ]
              });
              this.doTrnasaction(inbuiltAddress, walletAddress)
            }
            else {
              this.service.toasterErr(err.message)

              console.log(err);
              this.service.hideSpinner()
            }
          }
        }
      } catch (error) {
        this.service.toasterErr(error.message)

        console.log(error);
        this.service.hideSpinner()
        return
      }


    } else {
      this.service.hideSpinner()
      console.error('MetaMask not found');
      this.service.toasterErr('MetaMask not found')
    }
  }

  async doTrnasaction(inbuiltAddress, walletAddress) {
    try {
      // Request account access
      let isConnected = await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('MetaMask connected', isConnected);
      debugger
      const web3 = new Web3(window.ethereum);
      const constant = new contractHelper()
      const newVestingContract = await new web3.eth.Contract(constant.nodeContractDeployAbi, this.viewData.planId.contractAddress);
      const txObject = {
        from: isConnected[0],
        // to: this.viewData.planId.contractAddress,
        gasPrice: Web3.utils.toHex('2000000000'),    // Always in Wei
        gasLimit: Web3.utils.toHex('800000'),
        // value: Web3.utils.toWei(this.viewData.buyAmount.toString(), "ether"),
      };
      // const txHash = await window.ethereum.request({ method: 'eth_sendTransaction', params: txObject });
      const txHash = await newVestingContract.methods.changeUserClaimAddress(
        inbuiltAddress, walletAddress
      ).send(txObject)
      console.log('Transaction sent:', txHash.transactionHash, txHash);
      let gasInWie = String(txHash.gasUsed * 10000000000)
      let ethAmount = Web3.utils.fromWei(gasInWie, 'ether')
      this.createTransaction(txHash.transactionHash, ethAmount)
    } catch (error) {
      this.service.hideSpinner()
      console.error(error);
      this.service.toasterErr(error.message)
    }
  }

  createTransaction(txnHash, ethAmount) {
    this.service.showSpinner();
    const url = `wallet/createTransaction`
    const data = {
      _id: this.viewData._id,
      userId: this.viewData.userId._id,
      planId: this.viewData.planId._id,
      transactionHash: txnHash,
      priceAmount: ethAmount,

    }

    this.service.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc("Accepted successfully.");
        $("#approveModal").modal("hide");
        this.coinList();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }

  mfa = false
  params: any
  twoFAStatus: any
  viewData: any
  twoFAStatusFun(id, item) {
    this.params = {
      id
    }
    this.viewData = item
    if (this.twoFAStatus == 'NONE' || this.twoFAStatus == 'SKIP') {
      this.approved(id)
    }
    else {
      // $('#googleAuth').modal({ backdrop: 'static' });
      this.mfa = true
    }
  }
  verfyAuth(params) {
    this.approved(params.id)
  }
  approveKyc() {
    this.service.showSpinner();
    const url = `wallet/vesting-management/admin/claim-token?tokenRequest=CONFIRM&txnId=${this.tokenId}`
    this.service.put(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc("Accepted successfully.");
        $("#approveModal").modal("hide");
        this.coinList();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  rejectTokenRequet() {
    this.service.showSpinner();
    const url = `wallet/vesting-management/admin/claim-token?tokenRequest=REJECTED&txnId=${this.tokenIdReject}`
    this.service.put(url, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc("Rejected successfully.");
        $("#rejectTokenModal").modal("hide");
        this.coinList();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message);
      }
    },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
  // My Profile Functionality
  myProfile() {
    var url = 'admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data']
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }
  userDetailData: any
  functionNew() {
    return this.service.toasterErr('This feature is only capable for admin!')
  }
  functionNewReject() {
    return this.service.toasterErr('This feature is only capable for admin!')
  }
  getClaimData() {
    let url = `wallet/vesting-management/admin/get-claim-data`
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetailData = res['data']
      } else {
        // this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        // this.service.toasterErr(err.error.message);
      }
    })
  }
}
