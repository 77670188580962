<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">IP Whitelisting Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <div class="d-flex justify-content-between">

                                <p class="text-muted" style="margin-top: 15px;">Allow only ip addresses added to the
                                    whitelist
                                    to have access on Admin panel
                                </p>


                                <button class="btn btn-theme" *ngIf="status == 'ACTIVE'"
                                    style="background: green;">ENABLED</button>
                                <button class="btn btn-theme" *ngIf="status == 'BLOCKED'"
                                    style="background: #bf3104;">DISABLED</button>
                                <button class="btn btn-theme ml-2" (click)="enableDisable(status)">{{status == 'ACTIVE'
                                    ? 'DISABLE' : 'ENABLE'}} Now</button>
                                <button class="btn btn-theme" (click)="openAddModal()">+ Add Ip address</button>

                            </div>
                            <p class="text-muted"><b>Your current IP is : </b>{{ip}}</p>
                        </div>
                        <!-- <div class="row  d-flex justify-content-end w_100">

                            <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Ip Address <i class="fa fa-sort"
                                                (click)="sortAgent('description');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="ticketList?.length">
                                    <tr class="new-hgt-tab" *ngFor="let item of ticketList| paginate: { 
                                        itemsPerPage: itemsPerPage,
                                        currentPage: pageNumber,
                                        totalItems: totalRecords}; let i=index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <!-- <td class="elips">{{item.description || 'N/A'}}</td> -->
                                        <td>{{item.status || 'N/A'}}</td>
                                        <td>{{item.ipAddress || 'N/A'}}</td>
                                        <!-- <td>{{item.createdAt | date: 'medium'}}</td>
                                        <td>{{item.firstName || 'N/A'}}</td>
                                        <td class="new-hgt" *ngIf="service.isShow(permissionArr,'QUERY_MANAGEMENT','UPDATE')" style="min-width: 155px;">
                                            <select name="" id="" class="form-control hgt"
                                                (change)="updateTicketStatus($event,item.ticketId)">
                                                <option *ngFor="let status of arr"
                                                    [selected]="status == item.ticketStatus ? true : false">
                                                    <span>
                                                        {{status}}
                                                    </span>
                                                </option>

                                            </select>
                                        </td> -->

                                        <td class="action_td_btn3"
                                            *ngIf="service.isShow(permissionArr,'QUERY_MANAGEMENT','UPDATE')">
                                            <a (click)="openEditModal(item.ipAddress,item._id)"><i class="fa fa-edit"
                                                    aria-hidden="true"></i></a>
                                            <a (click)="block('ENABLE',item?._id)" *ngIf="item?.status=='ACTIVE'"
                                                data-toggle="modal"><i class="fa fa-ban" aria-hidden="true"
                                                    style="color: green;"></i></a>


                                            <a (click)="block('DISABLE',item?._id)" *ngIf="item?.status=='BLOCKED'"
                                                data-toggle="modal"><i class="fa fa-ban" aria-hidden="true"></i></a>
                                            <a (click)="openDeleteModal(item._id)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></a>


                                        </td>
                                    </tr>

                                </tbody>
                                <tbody *ngIf="!ticketList?.length">
                                    <tr>
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5>No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div> -->
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="addModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Add IP Address </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <input type="text" class="form-control" placeholder="Enter IP Address"
                                    [formControl]="ipdAddress">

                                <span class="error">
                                    <p *ngIf="ipdAddress.invalid && (ipdAddress.touched || ipdAddress.dirty)" style="color: red;
                                    font-size: small !important;
                                    text-align: left;" class="error" padding>Inavlid IP Address.</p>
                                </span>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-info mr-2" [disabled]="ipdAddress.invalid" (click)="onadd()">Save</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="editModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Edit IP Address </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <input type="text" class="form-control" placeholder="Enter IP Address"
                                [formControl]="editIp">
                                <span class="error">
                                    <p *ngIf="editIp.invalid && (editIp.touched || editIp.dirty)" style="color: red;
                                        font-size: small !important;
                                        text-align: left;" class="error" padding>Inavlid IP Address.</p>
                                </span>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-info mr-2" [disabled]="editIp.invalid" (click)="onedit()">Save</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete IP Address</h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this IP address?</p>
                                <div class="mt-3">
                                    <button type="submit" class="btn btn-info mr-2" (click)="onDelete()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="enableip">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Enable IP Address </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to enable this ip address?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="enableip()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="disableip">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Disable IP Address </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to disable this ip address?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2" (click)="enableip()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="Allenableip">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Enable IP Address </h3>

                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to enable this ip address?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        (click)="onEnableDisable()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="Alldisableip">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Disable IP Address </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to disable this ip address?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        (click)="onEnableDisable()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>