<div class="wrapper">
  <main class="middle-content">
    <div class="page_title_block">
      <h1 class="page_title" style="color: black">Change Password</h1>
    </div>
    <div class="content-section">
      <div class="order-view   setting-page" style="max-width: 100%">
        <div class="  mb40">

          <div class="add-store-block input-style mb20 mt20 ">
            <form [formGroup]="form" #f="ngForm">
              <div class="form-group row align-items-baseline  postion-relative view-label-align">
                <label class="col-md-5 textalignright ">Old Password <span>:</span></label>
                <div class="col-md-7 textaligncenter">
                  <div class="form-group">
                    <input [type]="passType  ? 'text' : 'password'" class="form-control" placeholder="Current Password"
                      formControlName="oldPassword" maxlength="30" oncopy="return false" onpaste="return false" ondrag="return false">
                    <span class="eye-icon" (click)="passType = !passType"> <i
                        [ngClass]="passType ? 'fa fa-eye': 'fa fa-eye-slash'"></i> </span>
                    <div
                      *ngIf="form.get('oldPassword').invalid && (form.get('oldPassword').touched || form.get('oldPassword').dirty)"
                      class="text-danger">
                      <span *ngIf="form.get('oldPassword').hasError('required') ">*Please enter old password.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group  postion-relative row align-items-baseline view-label-align">
                <label class="col-md-5 textalignright ">New Password <span>:</span></label>
                <div class="col-md-7 textaligncenter">
                  <div class="form-group">
                    <input [type]="passType1 ? 'text' : 'password'" class="form-control" placeholder="New Password"
                      formControlName="newPassword" maxlength="30" oncopy="return false" onpaste="return false" ondrag="return false">
                    <span class="eye-icon" (click)="passType1 = !passType1"> <i
                        [ngClass]="passType1 ? 'fa fa-eye':'fa fa-eye-slash'"></i> </span>
                    <div
                      *ngIf="form.get('newPassword').invalid && (form.get('newPassword').touched || form.get('newPassword').dirty)"
                      class="text-danger">
                      <span *ngIf="form.get('newPassword').hasError('required')">
                        *Please enter new password.</span>
                      <span *ngIf="form.get('newPassword').hasError('pattern')">
                        *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1
                        number and 1 special character.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row  postion-relative align-items-baseline view-label-align">
                <label class="col-md-5 textalignright ">Confirm Password <span>:</span></label>
                <div class="col-md-7 textaligncenter">
                  <div class="form-group">
                    <input [type]="passType2 ? 'text' : 'password'" class="form-control"
                      placeholder="Confirm New Password" formControlName="confirmPassword" maxlength="30" oncopy="return false" onpaste="return false" ondrag="return false">
                    <span class="eye-icon" (click)="passType2 = !passType2"> <i
                        [ngClass]="passType2 ? 'fa fa-eye':'fa fa-eye-slash'"></i> </span>
                    <div *ngIf="form.get('confirmPassword').dirty" class="text-danger">
                      <span *ngIf="form.get('confirmPassword').value != form.get('newPassword').value">*Password
                        mismatched.</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="text-center  mb40">
            <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="form.invalid" (click)="changePassword()"
              style="justify-content: space-between;     background: #333;">Update</button>&nbsp;&nbsp;
            <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/my-profile">Back</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<footer>
  <div class="container-fluid">
    <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved. <strong class="theme-text-color"></strong>
    </p>
  </div>
</footer>