import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-trx-manage',
  templateUrl: './trx-manage.component.html',
  styleUrls: ['./trx-manage.component.css']
})
export class TrxManageComponent implements OnInit {

  constructor(public service:MainService) { }
  address:any
  ngOnInit(): void {
    this.getTotalCount()
  }


  getCount(){
    let url = `wallet/wallet/trx-count?count=${this.address}`
    this.service.showSpinner()
    this.service.postApi(url,{}).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        // this.service.toasterSucc(res.message);
        this.service.hideSpinner();
        // this.router.navigate(['/whitepaper-list'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    },
    (err)=>{
      this.service.hideSpinner()
     })
  }
  totalCountdata:any
  getTotalCount(){
    let url = `wallet/wallet/get-remaining-count`
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.status == 200) {
        this.totalCountdata = res.data
        // this.service.toasterSucc(res.message);
        this.getCount()
        this.service.hideSpinner();
        // this.router.navigate(['/whitepaper-list'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    },
    (err)=>{
      this.service.hideSpinner()
     })
  }
}
