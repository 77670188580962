import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;
  showEyeOrCrossEye: boolean = false;
  showEyeOrCrossEye2: boolean = false;
  constructor(public service: MainService,public route:Router) { 
    localStorage.clear()
  }

  ngOnInit() {
this.token = window.location.href.split('=')[1];

    this.resetPasswordForm = new FormGroup({
      password: new FormControl('',[Validators.required,Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      confirmPassword : new FormControl('',[Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)])      
    })
  }

  
  // Reset Password Functionality
  resetPasswordFunc(){
    var apireq = {
      'newPassword': this.resetPasswordForm.value.password,
      // 'token': this.token
    }
    this.service.showSpinner();
    this.service.put('admin/resetPassword/'+this.token,apireq).subscribe(res=>{
     
      this.service.hideSpinner();
      if(res['status']==200){
        this.service.toasterSucc('Password Set Successfully');
        this.route.navigate(['/login'])
      }
    }, err=>{
    
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr(err.error.message);
   }
    })

    
  }
}
  
