import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-admin-detail',
  templateUrl: './edit-admin-detail.component.html',
  styleUrls: ['./edit-admin-detail.component.css']
})
export class EditAdminDetailComponent implements OnInit {

  addAdmin: FormGroup;
  editImage: any;
  subAdminData: any;
  newArr: any = [];
  userDeatil = localStorage.getItem('usertype')

  permArray = [
    {
      id: 1,
      keyName: 'USER_MANAGEMENT',
      keyValue: 'User Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    
    {
      id: 2,
      keyName: 'TOKEN_TRANS_MANAGEMENT',
      keyValue: 'Sale Trances Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 3,
      keyName: 'TRANSACTION_MANAGEMENT',
      keyValue: 'Transactions Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 4,
      keyName: 'PAYMENT_MANAGEMENT',
      keyValue: 'Payment Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 5,
      keyName: 'KYC_MANAGEMENT',
      keyValue: 'KYC Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 6,
      keyName: 'QUERY_MANAGEMENT',
      keyValue: 'Queries Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 7,
      keyName: 'STATIC_CONTENT_MANGEMENT',
      keyValue: 'Static Content Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 8,
      keyName: 'TOKEN_MANAGEMENT',
      keyValue: 'Token Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },

    {
      id: 9,
      keyName: 'FIERES_TUTORIAL',
      keyValue: 'Fieres Academy Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 10,
      keyName: 'CLAIM_TOKEN_MANAGEMENT',
      keyValue: 'Claim Token Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    },
    {
      id: 11,
      keyName: 'VESTING_MANAGEMENT',
      keyValue: 'Vesting Management',
      access: ['CREATE', 'UPDATE', 'READ', 'DELETE']
    }
  ]
  constructor(public route: Router, public service: MainService, public active: ActivatedRoute) {
    this.active.params.subscribe((params) => {
      this.userId = params.id
    })
  }

  ngOnInit(): void {
    this.addAdmin = new FormGroup({
      'name': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'contact': new FormControl('', [Validators.required, Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      // 'password': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)])),
      // 'confirmPassword': new FormControl('', Validators.compose([Validators.required])),
      // 'userName': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'reward': new FormControl('')

    })
    this.viewAdmin()
  }
  userId: any;
  viewData: any;
  imageUrl: any;
  Previlage: any
  viewAdmin() {
    this.service.showSpinner();
    var url = "admin/subAdminView/" + this.userId
    this.service.get(url).subscribe((res: any) => {
      console.log("gf", res);
      if (res.status == 200) {
        this.service.hideSpinner();
        this.viewData = res.data
        this.imageUrl = res.data.profilePic
        // let str = String(res.data.Previlage).replace(/'/gi, '"')
        this.Previlage = res.data.permission
        this.addAdmin.patchValue({
          'name': res.data.firstName,
          'lastName': res.data.lastName,
          'email': res.data.email,
          'contact': res.data.phoneNo,
          'reward' : res.data.rewarded
        })


        for (const iterator in this.Previlage) {
          for (const iterator2 of this.Previlage[iterator]) {
            console.log(iterator2);

            this.updatePermission(true, iterator, iterator2)
          }

        }

      }

    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }

  profilePic  : any
  // Image Functionality Start Here
  uploadImg($event): void {
    this.editImage = $event.target.files[0];
    this.getBase64(this.editImage,(res)=>{
      this.profilePic = res
    });
  }
  getBase64 (file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (err) {
      console.log("Error: ", err);
    };
  };
  // Function for checkbox click 

  checkboxClick(value, checked) {
    console.log("permission->", value, checked)
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      console.log(index)
      this.newArr.splice(index, 1)
      console.log("new arr->", this.newArr);
    }
  }


  // api of add subadmin
  addSubAdmin() {
    let previlage = ['DASHBOARD']
    for (const key in this.perm_obj) {
      if (this.perm_obj[key].length) {
        previlage.push(key)
      }
    }

    this.service.showSpinner();
    let subAdminDto = {
      firstName: this.addAdmin.value.name,
      lastName: this.addAdmin.value.lastName,
      email: this.addAdmin.value.email,
      phoneNo: this.addAdmin.value.contact,
      imageUrl: this.editImage ,
      // password: this.addAdmin.value.password,
      // roleStatus: 'SUBADMIN',
      authorityList: JSON.stringify(this.perm_obj),
      // address: '',
      // country: '',
      // gender: '',
      // rewarded : this.addAdmin.value.reward,
      // previlage: previlage,
      webUrl: this.service.websiteURL + 'reset-password'
    }

    const newRwq =this.service.bodyToData(subAdminDto)
    this.service.post('admin/editPermission?_id=' + this.userId, newRwq).subscribe((res: any) => {
      console.log('f', res);
      if (res.status == 200) {
        this.subAdminData = res.data
        this.service.hideSpinner();
        this.service.toasterSucc(res.message)
        this.route.navigate(['/user-management-admin']);
      }
      else if (res.status == 205) {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }






  perm_obj = {
    'USER_MANAGEMENT': [],
    'TOKEN_TRANS_MANAGEMENT': [],
    'TRANSACTION_MANAGEMENT': [],
    'PAYMENT_MANAGEMENT': [],
    'KYC_MANAGEMENT': [],
    'QUERY_MANAGEMENT': [],
    'STATIC_CONTENT_MANGEMENT': [],
    'TOKEN_MANAGEMENT': [],
    'FIERES_TUTORIAL': [],
    'CLAIM_TOKEN_MANAGEMENT': [],
    'VESTING_MANAGEMENT': []
  }
  updatePermission(isChecked, managementName, type) {


    let arr = this.perm_obj[managementName]

    if (isChecked) {
      arr.push(type)
      console.log(type);

      this.perm_obj[managementName] = arr
    } else {
      arr.splice(this.perm_obj[managementName].indexOf(type), 1)
      this.perm_obj[managementName] = arr
    }
    console.log(this.perm_obj);

  }

  isChecked(perm, permType) {
    return this.Previlage[perm]?.includes(permType)
  }

}
