import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { Router, Event, NavigationEnd } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  currUrl: string;
  isLoggedIn: boolean;
  userDetail: any = {};
  getperm: any;
  getrole: any;
  role: any
  getpermission: any;
  permissionList: any;
  flag: boolean = false;

  showLogo: boolean = false;
  userID: any;
  previlage: any;

  permissionArr: any

  constructor(private routes: Router, public service: MainService) {
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (localStorage.Auth) {
          this.service.changeLoginSub('login')
          if ((this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == `reset-password` || this.currUrl == ``)) {
            this.routes.navigate([`/dashboard`])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl.includes(`reset-password`) || this.currUrl == ``)) {
            this.routes.navigate([`/login`])
          }
          this.service.changeLoginSub('logout');
        }
      }
    })

    this.service.permission.subscribe((res) => {
      if (Object.keys(res).length) {
        this.permissionArr = res
        console.log(res);

      }
    })
  }

  gettemp(e) {
    console.log(e);

  }
  ngOnInit() {

    this.service.loginObs.subscribe(response => {
      if (response == 'login') {
        this.isLoggedIn = true;
        console.log('jf', this.isLoggedIn);

        this.myProfile();
      }
      else {
        this.isLoggedIn = false;
      }
    })
  }

  // head() {
  //     if (document.getElementById("mysidebar").className =="lg-logo") {
  //       document.getElementById("mysidebar").className ="lg-logo1";
  //     }

  //     else if (document.getElementById("mysidebar").className =="lg-logo1") {
  //       document.getElementById("mysidebar").className ="lg-logo";
  //     }
  // }
  head() {

    if (this.showLogo == false) {
      if ((document.getElementById("logo").style.width = "45px")) {
        document.getElementById("logo").style.display = "none";




        this.showLogo = true;
      }
    }
    else {
      document.getElementById("logo1").style.display = "none";


      this.showLogo = false;
    }
    // let bodyId = document.getElementById('defined-body')
    // let button = document.getElementsByTagName('body')[0]
    // button.classList.toggle('toggle-wrapper')

    const body = document.querySelector('body');
    if (body.classList.contains('toggle-wrapper')) {
      // Remove the class if it's already present
      body.classList.remove('toggle-wrapper');
    } else {
      // Add the class if it's not present
      body.classList.add('toggle-wrapper');
    }
  }

  // My Profile Functionality
  myProfile() {
    var url = 'admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.userID = res['data'].userId;
        this.previlage = res['data'].previlage;
        this.role = res['data'].role;
        localStorage.setItem('userId', this.userID);
        localStorage.setItem('permission', this.previlage);
        localStorage.setItem('usertype', this.role);
        this.getrole = (localStorage.getItem('usertype'))
        this.getpermission = (localStorage.getItem('permission'))
        this.permissionList = this.getpermission.split(",");
        this.service.twoFA.next(res['data']['twoFaType'])

        if (this.getrole == "SUBADMIN") {
          this.flag = true;
          let str = res.data.permission
          this.service.permission.next(str)
        }







      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Session Expired');
      } else {
        this.service.toasterErr(err.error.message);
      }
    })
  }




  // logout
  onLogout() {
    localStorage.removeItem('data');
    localStorage.removeItem('Auth');
    localStorage.removeItem('permission');
    localStorage.removeItem('usertype');
    $('#signout_modal').modal('hide');
    window.location.reload();
    this.routes.navigate(['/login']);
  }

}
