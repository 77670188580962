<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">DASHBOARD</h1>
        </div>
        <div class="content-section dashboard-block">
            <ul class="dash_list d-flex w-100 flex-wrap text-center ">
                <li class="d-inline-flex align-items-center w-100 justify-content-center bg-green"
                    style="cursor: pointer;width: 100% !important;">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-users-cog fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Token Sold</h2>
                            <!-- <h4>{{userList?.totalTokenSold}}</h4> -->
                            <h4>{{userList?.totalTokenSold}}</h4>
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="dash_list d-flex w-100 flex-wrap text-center ">

                <li class="d-inline-flex align-items-center justify-content-center bg-blue" style="cursor: pointer;"
                    routerLink="/user-management"  *ngIf="isShow('USER_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-users fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Registered Users</h2>
                            <h4>{{userList?.totalUserCount}}</h4>
                        </div>
                    </div>
                </li>


                <li class="d-inline-flex align-items-center justify-content-center bg-green" style="cursor: pointer;"
                    routerLink="/user-management"  *ngIf="isShow('USER_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-user-ninja fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Active Users</h2>
                            <h4>{{userList?.activeUserCount}}</h4>
                        </div>
                    </div>

                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    routerLink="/user-management"  *ngIf="isShow('USER_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-user-slash fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Blocked Users</h2>
                            <h4>{{userList?.blockUserCount}}</h4>
                        </div>
                    </div>

                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-red" style="cursor: pointer;"
                    routerLink="/user-management"  *ngIf="isShow('USER_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-user-times fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Unverified Users</h2>
                            <h4>{{userList?.pendingUserCount}}</h4>
                        </div>
                    </div>

                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue" style="cursor: pointer;"
                    routerLink="/kyc-update"  *ngIf="isShow('KYC_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total KYC</h2>
                            <h4>{{userList?.countAllKyc}}</h4>
                        </div>
                    </div>

                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue" style="cursor: pointer;"
                    routerLink="/kyc-update" *ngIf="isShow('KYC_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Pending KYC</h2>
                            <h4>{{userList?.countPendingKyc}}</h4>
                        </div>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue" style="cursor: pointer;"
                    routerLink="/kyc-update" *ngIf="isShow('KYC_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Completed KYC</h2>
                            <h4>{{userList?.countCompeletedKyc}}</h4>
                        </div>
                    </div>

                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-green" style="cursor: pointer;"
                    routerLink="/transactionList" *ngIf="isShow('TRANSACTION_MANAGEMENT')">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-users-cog fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total No. Transaction</h2>
                            <h4>{{userList?.transactionCount}}</h4>
                        </div>
                    </div>
                </li>
                <!-- <li class="d-inline-flex align-items-center justify-content-center bg-green" style="cursor: pointer;"
                    routerLink="/hot-wallet-management">
                    <div class="gradientBox">
                        <div class="w-100">
                            <div class="dash_icn">
                                <i class="fas fa-users-cog fa-3x" aria-hidden="true"></i>
                            </div>
                            <h2>Total Wallet Deposit Fund</h2>
                            <h4>{{userList?.totalDepositCount}}</h4>
                        </div>
                    </div>

                </li> -->


            </ul>
        </div>
    </main>
</div>
<footer>
    <div class="container-fluid">
        <p class="copyright">FIERES ICO Crypto © 2024 All Rights Reserved.<strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>